import React, { useContext } from "react";
import { Col, Row } from "reactstrap";
import FormGroupText from "../../Common/FormGroupText";
import { withTranslation } from "react-i18next";
import { AuthContext } from "../../../store/AuthContext";

const CompanyIframForm = ({ company, validationSocialMedia, ...props }) => {
  const { user } = useContext(AuthContext);
  return (
    <>
      <Row>
        <Col lg="12">
          <FormGroupText
            idInput="facebook"
            label={props.t("Facebook")}
            attributeName="facebook"
            placeholder=""
            validation={validationSocialMedia}
            type="text"
          />
        </Col>
      </Row>

      <Row>
        <Col lg="12">
          <FormGroupText
            idInput="linkedin"
            label={props.t("Linkedin")}
            attributeName="linkedin"
            placeholder=""
            validation={validationSocialMedia}
            type="text"
          />
        </Col>
      </Row>

      <Row>
        <Col lg="12">
          <FormGroupText
            idInput="instagram"
            label={props.t("Instagram")}
            attributeName="instagram"
            placeholder=""
            validation={validationSocialMedia}
            type="text"
          />
        </Col>
      </Row>

      <Row>
        <Col lg="12">
          <FormGroupText
            idInput="youtube"
            label={props.t("Youtube")}
            attributeName="youtube"
            placeholder=""
            validation={validationSocialMedia}
            type="text"
          />
        </Col>
      </Row>
    </>
  );
};

export default withTranslation()(CompanyIframForm);
