import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  createCompany,
  findCompany,
  deleteCompany,
  getAllCompanies,
  getCompanyImages,
  getCompanyLogo,
  updateCompany,
  updateCompanyStatus,
} from "../services/company";
import { toast } from "react-toastify";

export function useAddCompany() {
  const queryClient = useQueryClient();
  return useMutation(({ data }) => createCompany(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(["companies"]);
    },
    onError: (error) => {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message, {
          position: "top-right",
          hideProgressBar: true,
          closeOnClick: false,
          className: "bg-danger text-white",
        });
      }
    },
  });
}

export function useFindCompany(id) {
  return useQuery(["companies", id], () => findCompany(id), {
    enabled: !!id,
  });
}

export function useAllCompanies() {
  return useQuery(["companies"], getAllCompanies);
}

export function useUpdateCompany(id) {
  const queryClient = useQueryClient();
  return useMutation(({ id, data, isCoverImage }) => updateCompany(id, data, isCoverImage), {
    onSuccess: () => {
      queryClient.invalidateQueries(["companies"]);
    },
  });
}

export function useDeleteCompany() {
  const queryClient = useQueryClient();
  return useMutation(({ id }) => deleteCompany(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["companies"]);
    },
  });
}

export function useUpdateStatus() {
  const queryClient = useQueryClient();

  return useMutation(({ id, data }) => updateCompanyStatus(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(["companies"]);
    },
    onError: (error) => {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message, {
          position: "top-right",
          hideProgressBar: true,
          closeOnClick: false,
          className: "bg-danger text-white",
        });
      }
    }
  });
}

export function useCompanyImages(name) {
  return useQuery(["slider", name], () => getCompanyImages(name), {
    enabled: !!name,
  });
}

export function useCompanyLogo(name) {
  console.log("name",name)
  return useQuery(["logo", name], () => getCompanyLogo(name), {
    enabled: !!name,
  });
}
