import React, { createContext, useState } from "react";

export const ReserveAppointmentContext = createContext({});

const ReserveAppointmentContextProvider = ({ children }) => {
  const [appointmentData, setAppointmentData] = useState(null);

  const onAppointmentData = (data) => {
    setAppointmentData((prev) => ({ ...prev, ...data }));
  };

  const onResetAppointmentData = () => {
    setAppointmentData(null);
  };

  return (
    <ReserveAppointmentContext.Provider
      value={{
        appointmentData,
        onAppointmentData,
        onResetAppointmentData,
      }}
    >
      {children}
    </ReserveAppointmentContext.Provider>
  );
};

export default ReserveAppointmentContextProvider;
