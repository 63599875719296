export default {
    search: {
      placeholder: 'Suchen...',
    },
    sort: {
      sortAsc: 'Spalte aufsteigend sortieren',
      sortDesc: 'Spalte absteigend sortieren',
    },
    pagination: {
      previous: 'zurück',
      next: 'vor',
      navigate: (page, pages) => `Seite ${page} von ${pages}`,
      page: (page) => `Seite ${page}`,
      showing: 'Anzeigen',
      of: 'von',
      to: 'bis',
      results: 'Ergebnisse',
    },
    loading: 'Wird geladen...',
    noRecordsFound: 'Keine übereinstimmenden Aufzeichnungen gefunden',
    error: 'Beim Abrufen der Daten ist ein Fehler aufgetreten',
  };