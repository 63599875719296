import React from "react";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
  Spinner,
} from "reactstrap";
import Slide from "../../Components/Common/UiCarousel/CarouselTypes/slide";
import { ReactComponent as PoweredByIcon } from "../../assets/images/powered-by.svg";

import { Link, withRouter } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
//i18n
import { withTranslation } from "react-i18next";
//Import config
import LanguageDropdown from "../../Components/Common/LanguageDropdown";
import useSendForgotPassword from "../../hooks/useSendForgotPassword";
//import images

const Login = (props) => {
  const {
    mutate: sendForgotPassword,
    isSuccess: successfullySendLink,
    isLoading: isSendingLink,
    isFetching: isFatchingLink,
    data: sendForgotPasswordData,
    error: sendForgotPasswordError,
  } = useSendForgotPassword();
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required(props.t("username_validation")),
    }),
    onSubmit: (values) => {
      sendForgotPassword({ data: values });
    },
  });

  return (
    <div className="auth-page-wrapper" style={{ height: "100vh" }}>
      <div className="auth-page-content">
        <MetaTags>
          <title>{props.t("MyHealth Brend")}</title>
        </MetaTags>
        <Container fluid>
          <Row style={{ height: "100vh" }}>
            <Col md={12} lg={6} xl={6} className="g-0">
              <Row style={{ backgroundColor: "white", paddingLeft: "10px" }}>
                <Col md={1}>
                  <LanguageDropdown />
                </Col>
                <Col md={11}>
                  <Card
                    className="m-0 w-100"
                    style={{
                      height: "100vh",
                      display: "flex",
                      boxShadow: "none",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CardBody>
                      <div style={{ marginLeft: "15%", marginRight: "15%" }}>
                        <div className="text-center mt-2">
                          <h5 className="text-primary">
                            {props.t("Forgot password")}?
                          </h5>
                          <p className="text-muted">
                            {props.t("Reset password with email")}
                          </p>

                          <lord-icon
                            src="https://cdn.lordicon.com/rhvddzym.json"
                            trigger="loop"
                            colors="primary:#0ab39c"
                            className="avatar-xl"
                            style={{ width: "120px", height: "120px" }}
                          ></lord-icon>
                        </div>

                        <Alert
                          className="alert-borderless alert-warning text-center mb-2 mx-2"
                          role="alert"
                        >
                          {props.t(
                            "Enter your email and instructions will be sent to you!"
                          )}
                        </Alert>
                        <div className="p-2">
                          {sendForgotPasswordError && (
                            <Alert color="danger" style={{ marginTop: "13px" }}>
                              {sendForgotPasswordError?.response?.data?.message}
                            </Alert>
                          )}
                          {sendForgotPasswordData && (
                            <Alert
                              color="success"
                              style={{ marginTop: "13px" }}
                            >
                              {sendForgotPasswordData?.message}
                            </Alert>
                          )}
                          <Form
                            onSubmit={(e) => {
                              e.preventDefault();
                              validation.handleSubmit();
                              return false;
                            }}
                          >
                            <div className="mb-4">
                              <Label className="form-label">
                                {props.t("Email")}
                              </Label>
                              <Input
                                name="email"
                                className="form-control"
                                placeholder={props.t("Enter Email")}
                                type="email"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email &&
                                  validation.errors.email
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.email &&
                              validation.errors.email ? (
                                <FormFeedback type="invalid">
                                  <div>{validation.errors.email}</div>
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="text-center mt-4">
                              <button
                                disabled={isSendingLink || isSendingLink}
                                className="btn btn-success w-100"
                                type="submit"
                              >
                                {isSendingLink || isSendingLink ? (
                                  <Spinner />
                                ) : (
                                  props.t("Send Reset Link")
                                )}
                              </button>
                            </div>
                            <div className="float-end mt-1">
                              <Link to="/login" className="text-muted">
                                Back to login?
                              </Link>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col md={12} lg={6} xl={6} className="g-0">
              <Slide />
            </Col>
          </Row>
          <PoweredByIcon
            onClick={() =>
              window.open("https://resolut-technologies.ch/", "_blank")
            }
            style={{
              position: "fixed",
              cursor: "pointer",
              left: "30px",
              bottom: "20px",
            }}
          />
        </Container>
      </div>
    </div>
  );
};

export default withRouter(withTranslation()(Login));
