import Select from "react-select";
import React from "react";
import { Col, FormGroup, Label, Row, Spinner } from "reactstrap";

const CustomSelect = ({
  label,
  onChange,
  selectOptions,
  selectValues,
  isDisabled = false,
  isMulti = false,
  children,
  isRequired = false,
  ...props
}) => {
  const defaultSelectValue = (options, value) => {
    if (isMulti) {
      if (options) {
        return options?.filter((option) => value.indexOf(option.value) >= 0);
      } else {
        return "";
      }
    } else {
      if (options) {
        return options?.find((option) => option.value === value);
      } else {
        return "";
      }
    }
  };

  return (
    <FormGroup>
      {label !== undefined && (
        <Label htmlFor="choices-single-default" className="form-label">
          {label} {isRequired && <span className="text-danger">*</span>}
        </Label>
      )}
      <Select
        name="country"
        value={defaultSelectValue(selectOptions, selectValues)}
        onChange={onChange}
        isDisabled={isDisabled}
        options={selectOptions}
        isMulti={isMulti}
        {...props}
      />
      {children && children}
    </FormGroup>
  );
};

export default CustomSelect;
