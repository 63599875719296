import React from "react";
import { Alert, Col, Form, FormGroup, Input, Row } from "reactstrap";
import FormGroupText from "../../../Components/Common/FormGroupText";
import { useFormik } from "formik";
import * as Yup from "yup";
import { withTranslation } from "react-i18next";

import { useFindEmployee } from "../../../hooks/useEmployees";

const CompanyOwnerForm = ({ company, handleUpdateCompany, ...props }) => {
  const { data: user } = useFindEmployee(company?.company_user);

  const validationOwner = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      gender: user?.gender || "",
      fname: user?.fname || "",
      lname: user?.lname || "",
      phone: user?.phone || "",
      email: user?.email || "",
      glnumber: user?.gln || "",
    },
    validationSchema: Yup.object({
      gender: Yup.string().required(props.t("You must select gender")),
      fname: Yup.string().required(props.t("Please Enter First Name")),
      lname: Yup.string().required(props.t("Please Enter Last Name")),
    }),
    onSubmit: (values) => {
      handleUpdateCompany(user.companyId, values);
    },
  });
  return (
    <Form className="needs-validation">
      <Row>
        <Col lg={12}>
          <FormGroup>
            <label className="form-label">
              {props.t("Gender")} <span className="text-danger">*</span>
            </label>
            <div>
              <div className="form-check form-check-inline">
                <Input
                  readOnly
                  checked={user?.gender === "male" && "true"}
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="male"
                  onChange={(e) =>
                    validationOwner.setFieldValue("gender", e.target.value)
                  }
                  value="male"
                />
                <label className="form-check-label" htmlFor="male">
                  {props.t("Male")}
                </label>
              </div>
              <div className="form-check form-check-inline">
                <Input
                  readOnly
                  checked={user?.gender === "female" && "true"}
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="female"
                  onChange={(e) =>
                    validationOwner.setFieldValue("gender", e.target.value)
                  }
                  value="female"
                />
                <label className="form-check-label" htmlFor="female">
                  {props.t("Female")}
                </label>
              </div>
            </div>
            {validationOwner.touched.gender &&
              validationOwner.errors.gender && (
                <p
                  style={{
                    color: "#f06548",
                    marginTop: "0.25rem",
                    fontSize: "0.875em",
                  }}
                >
                  {validationOwner.errors.gender}
                </p>
              )}
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col lg="6">
          <FormGroupText
            readOnly
            idInput="fname"
            label={props.t("First name")}
            isRequired={true}
            attributeName="fname"
            placeholder=""
            validation={validationOwner}
            type="text"
          />
        </Col>
        <Col lg="6">
          <FormGroupText
            readOnly
            idInput="lname"
            label={props.t("Last name")}
            isRequired={true}
            attributeName="lname"
            placeholder=""
            validation={validationOwner}
            type="text"
          />
        </Col>
      </Row>

      <Row>
        <Col lg="6">
          <FormGroupText
            readOnly
            idInput="phone"
            label={props.t("Phone")}
            attributeName="phone"
            placeholder=""
            validation={validationOwner}
            type="tel"
          />
        </Col>
        <Col lg="6">
          <FormGroupText
            readOnly
            idInput="email"
            label={props.t("Email")}
            attributeName="email"
            placeholder=""
            validation={validationOwner}
            type="email"
          />
        </Col>
      </Row>

      <Row>
        <Col lg="12">
          <FormGroupText
            readOnly
            idInput="glnumber"
            label={props.t("GlnNumber")}
            attributeName="glnumber"
            placeholder=""
            validation={validationOwner}
            type="text"
          />
        </Col>
      </Row>
    </Form>
  );
};

export default withTranslation()(CompanyOwnerForm);
