import React, { useContext } from "react";
import { Button, Col, Form, Row } from "reactstrap";
import FormGroupText from "../../../Components/Common/FormGroupText";
import { useFormik } from "formik";
import * as Yup from "yup";
import { withTranslation } from "react-i18next";
import { AuthContext } from "../../../store/AuthContext";

const CompanyIframForm = ({ company, handleUpdateCompany, ...props }) => {
  const { user } = useContext(AuthContext);
  const validationSocialMedia = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      facebook: company?.facebook || "",
      linkedin: company?.linkedin || "",
      instagram: company?.instagram || "",
      youtube: company?.youtube || "",
    },
    validationSchema: Yup.object({
      facebook: Yup.string(),
      linkedin: Yup.string(),
      instagram: Yup.string(),
      youtube: Yup.string(),
    }),
    onSubmit: (values) => {
      handleUpdateCompany(user.companyId, values);
    },
  });
  return (
    <Form
      className="needs-validation"
      onSubmit={(e) => {
        e.preventDefault();
        validationSocialMedia.handleSubmit();
        return false;
      }}
    >
      <Row>
        <Col lg="12">
          <FormGroupText
            idInput="facebook"
            label={props.t("Facebook")}
            attributeName="facebook"
            placeholder=""
            validation={validationSocialMedia}
            type="text"
          />
        </Col>
      </Row>

      <Row>
        <Col lg="12">
          <FormGroupText
            idInput="linkedin"
            label={props.t("Linkedin")}
            attributeName="linkedin"
            placeholder=""
            validation={validationSocialMedia}
            type="text"
          />
        </Col>
      </Row>

      <Row>
        <Col lg="12">
          <FormGroupText
            idInput="instagram"
            label={props.t("Instagram")}
            attributeName="instagram"
            placeholder=""
            validation={validationSocialMedia}
            type="text"
          />
        </Col>
      </Row>

      <Row>
        <Col lg="12">
          <FormGroupText
            idInput="youtube"
            label={props.t("Youtube")}
            attributeName="youtube"
            placeholder=""
            validation={validationSocialMedia}
            type="text"
          />
        </Col>
      </Row>
      <Row className="text-end mt-3">
        <Col lg={12}>
          <Button className="btn btn-primary" type="submit">
            {props.t("Update")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default withTranslation()(CompanyIframForm);
