import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  Modal,
  ModalHeader,
  Spinner,
  Label,
  FormGroup,
  Form,
  Input,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import MetaTags from "react-meta-tags";
//i18n
import { withTranslation } from "react-i18next";

import { ArticleTable } from "./GridTablesData";

import FormGroupText from "../../Components/Common/FormGroupText";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  useAddArticle,
  useDeleteArticle,
  useFindArticle,
  useUpdateArticle,
} from "../../hooks/useArticles";
import ImageInput from "../../Components/Common/ImageInput";
import DeleteModal from "../../Components/Common/DeleteModal";
import { Editor } from "../../Components/Editor/editor";

const Articles = (props) => {
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [articleImage, setArticleImage] = useState();
  const { data: articlesData } = useFindArticle(selectedArticle);

  const {
    mutate: createArticle,
    isSuccess: isSuccessArticle,
    isLoading: isAddingArticle,
  } = useAddArticle();

  const {
    mutate: editArticle,
    isSuccess: isSuccessEditArticle,
    isLoading: isEditArticle,
  } = useUpdateArticle();

  const {
    mutate: deleteArticle,
    isSuccess: isSuccessDeleteArticle,
    isLoading: isDeletingArticle,
  } = useDeleteArticle();

  const [modal_standard, setmodal_standard] = useState(false);
  const [delete_modal, setdelete_modal] = useState(false);

  function tog_standard() {
    setmodal_standard(!modal_standard);
  }

  function tog_delete() {
    setdelete_modal(!delete_modal);
  }

  const deleteModal = () => {
    deleteArticle({ id: selectedArticle });
  };

  const handleEditArticle = (id, data) => {
    editArticle({ id, data });
    setSelectedArticle(null);
  };

  const handleOpenModal = (id) => {
    setSelectedArticle(id);
    tog_standard();
  };

  const handleDeleteModal = (id) => {
    setSelectedArticle(id);
    tog_delete();
  };

  useEffect(() => {
    if (isSuccessArticle && !isAddingArticle) {
      setmodal_standard(!modal_standard);
      validationArticle.resetForm();
    }
    if (isSuccessArticle && !isAddingArticle) {
      toast("Successfully added Article!", {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: false,
        className: "bg-success text-white",
      });
    }
  }, [isSuccessArticle, isAddingArticle]);

  useEffect(() => {
    if (isSuccessDeleteArticle && !isDeletingArticle) {
      tog_delete();
      setSelectedArticle(null);
    }
    if (isSuccessDeleteArticle && !isDeletingArticle) {
      toast(props.t("Successfully deleted Article!"), {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: false,
        className: "bg-danger text-white",
      });
    }
  }, [isSuccessDeleteArticle, isDeletingArticle]);

  useEffect(() => {
    if (isSuccessEditArticle && !isEditArticle) {
      setmodal_standard(!modal_standard);
      validationArticle.resetForm();
    }
    if (isSuccessEditArticle && !isEditArticle) {
      toast("Successfully edited Article!", {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: false,
        className: "bg-warning text-white",
      });
    }
  }, [isSuccessEditArticle, isEditArticle]);

  useEffect(() => {
    setContentValue(articlesData?.content)
  }, [articlesData])

  const [contentValue, setContentValue] = useState()
  const [error, setError] = useState("")

  useEffect(() => {
    if (contentValue) return setError("")
  },[contentValue])

  const validationArticle = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      image: "",
      title: articlesData?.title || "",
      overviewText: articlesData?.overviewText || ''
    },

    validationSchema: Yup.object({
      image: Yup.mixed().test(
        "article-image",
        "Please Add Article Image",
        function (value) {
          if (selectedArticle) return true;
          if (articleImage?.[0] === undefined) {
            return false;
          }
          return true;
        }
      ),
      title: Yup.string().required(props.t("Please Enter Title")),
      overviewText: Yup.string().required(props.t("Please Enter Overview Text")),
      content: Yup.string().test("content-validation", props.t("Please Enter Content"), function(value){
        if (!contentValue) return false
        return true
      }),
    }),

    onSubmit: (values, { resetForm }) => {
      const formData = new FormData();
      if (!contentValue) return setError(props.t("Please Enter Content"))
      formData.append("title", values.title);
      if (articleImage?.[0]) {
        formData.append("file", articleImage[0]);
      }
      formData.append("overviewText", values.overviewText)
      formData.append("content", contentValue);
      if (selectedArticle) {
        handleEditArticle(selectedArticle, formData);
      } else {
        createArticle(formData);
      }
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <MetaTags>
            <title>
              {props.t("Articles")} | {props.t("MyHealth Brend")}
            </title>
          </MetaTags>
          <BreadCrumb
            title={props.t("Articles")}
            pageTitle={props.t("Articles")}
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="align-items-center d-flex border-bottom border-3 border-primary">
                  <h4 className="card-title mb-0 flex-grow-1">
                    {props.t("Articles")}
                  </h4>
                  <div className="flex-shrink-0">
                    <div className="form-check form-switch form-switch-right form-switch-md">
                      <Button
                        className="btn btn-danger"
                        onClick={() => tog_standard()}
                      >
                        <i className="ri-add-fill label-icon align-middle me-2"></i>
                        {props.t("Create Article")}
                      </Button>
                    </div>
                  </div>
                </CardHeader>

                <CardBody>
                  <div id="table-gridjs">
                    <ArticleTable
                      handleOpenModal={handleOpenModal}
                      handleDeleteModal={handleDeleteModal}
                      deleteText={props.t("Delete")}
                      actionDoots={props.t("Action doots")}
                      edit={props.t("Edit")}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Article Modal */}
      <Modal
        className="modal-lg"
        id="myModal"
        onClosed={() => {
          setSelectedArticle(null);
          validationArticle.setTouched({}, false);
        }}
        isOpen={modal_standard}
        toggle={() => {
          ((!isAddingArticle && selectedArticle === null) ||
            (!isEditArticle && selectedArticle !== null)) &&
            tog_standard();
        }}
      >
        <ModalHeader
          className="p-3 bg-primary"
          toggle={() => {
            ((!isAddingArticle && selectedArticle === null) ||
              (!isEditArticle && selectedArticle !== null)) &&
              tog_standard();
          }}
        >
          <span className="text-white">{props.t("Article save")}</span>
        </ModalHeader>
        <Form
          className="needs-validation"
          onSubmit={(e) => {
            e.preventDefault();
            validationArticle.handleSubmit();
            if (!contentValue) return setError(props.t("Please Enter Content"))
            return false;
          }}
        >
          <div className="modal-body">
            {selectedArticle !== null && articlesData === undefined && (
              <Row>
                <Col lg="12">
                  <Spinner />
                </Col>
              </Row>
            )}
            <Row>
              <Col lg="12">
                <FormGroupText
                  disabled={
                    isAddingArticle ||
                    isEditArticle ||
                    (selectedArticle !== null && articlesData === undefined)
                  }
                  idInput="title"
                  label={props.t("Title")}
                  isRequired={true}
                  attributeName="title"
                  placeholder=""
                  validation={validationArticle}
                  type="text"
                />
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <FormGroupText
                  disabled={
                    isAddingArticle ||
                    isEditArticle ||
                    (selectedArticle !== null && articlesData === undefined)
                  }
                  idInput="overviewText"
                  label={props.t("Overview")}
                  isRequired={true}
                  attributeName="overviewText"
                  placeholder=""
                  validation={validationArticle}
                  type="text"
                />
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <Label htmlFor="content">
                  {props.t("Article Content")}{" "}
                  <span className="text-danger">*</span>
                </Label>
                <Editor name="content" value={contentValue} setValue={setContentValue} />
                {
                   error && (
                      <p
                        style={{
                          color: "#f06548",
                          marginTop: "0.25rem",
                          fontSize: "0.875em",
                        }}
                      >
                        {error}
                      </p>
                    )}  
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <ImageInput
                  label={props.t("Image")}
                  isRequired={!selectedArticle}
                  name="image"
                  accept="image/*"
                  onChange={(e) => {
                    setArticleImage(e.target.files);
                    validationArticle.handleChange();
                  }}
                  onBlur={validationArticle.handleBlur}
                  invalid={
                    validationArticle.touched.image &&
                    validationArticle.errors.image
                      ? true
                      : false
                  }
                  style={
                    validationArticle.touched.image &&
                    validationArticle.errors.image && {
                      borderColor: "#e74c3c",
                    }
                  }
                />
                {validationArticle.touched.image &&
                  validationArticle.errors.image && (
                    <p
                      style={{
                        color: "#f06548",
                        marginTop: "0.25rem",
                        fontSize: "0.875em",
                      }}
                    >
                      {validationArticle.errors.image}
                    </p>
                  )}
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            {(!isAddingArticle && selectedArticle === null) ||
            (!isEditArticle && selectedArticle !== null) ? (
              <>
                <Button
                  color="light"
                  onClick={() => {
                    tog_standard();
                    setSelectedArticle(null);
                  }}
                >
                  {props.t("Close")}
                </Button>
                <Button color="primary" type="submit">
                  {props.t("Submit")}
                </Button>
              </>
            ) : (
              <Button disabled color="primary" className="btn-load">
                <span className="d-flex align-items-center">
                  <span className="flex-grow-1 me-2">{props.t("Loading")}</span>
                  <Spinner size="sm" className="flex-shrink-0" role="status" />
                </span>
              </Button>
            )}
          </div>
        </Form>
      </Modal>
      <DeleteModal
        show={delete_modal}
        onCloseClick={tog_delete}
        onDeleteClick={deleteModal}
        isDeleteModal={isDeletingArticle}
      />
    </React.Fragment>
  );
};

export default withTranslation()(Articles);
