import { useMutation, useQuery, useQueryClient } from "react-query";

import { toast } from "react-toastify";
import { createUser, findUser, getEveryUser, updateUser } from "../services/users";
export function useEveryUser() {
  return useQuery(["users"], getEveryUser);
}

// export default function useEmployees() {
//   return useQuery(["employees"], getAllEmployees);
// }

export function useCreateUser() {
  const queryClient = useQueryClient();
  return useMutation(createUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(["users"]);
    },
    onError: (error) => {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message, {
          position: "top-right",
          hideProgressBar: true,
          closeOnClick: false,
          className: "bg-danger text-white",
        });
      }
    },
  });
}

export function useFindUser(id) {
  return useQuery(["users", id], () => findUser(id), {
    enabled: !!id,
  });
}

export function useUpdateUser(id) {
  const queryClient = useQueryClient();
  return useMutation(({ id, data }) => updateUser(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(["users"]);
    },
  });
}
