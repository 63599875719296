import api from "./axios";

export const createCompanyType = (data) => {
  return api.post("/company-type", data);
};

export const findCompanyType = (id) => {
  return api(`/company-type/${id}`);
};

export const getAllCompanyTypes = () => {
  return api("/all/types");
};

export const updateCompanyType = (id, data) => {
  return api.put(`/company-type/${id}`, data);
};

export const deleteCompanyType = (id) => {
  return api.delete(`/company-type/${id}`);
};
