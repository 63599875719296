import React from "react";
import { UncontrolledCarousel } from "reactstrap";
import "./Style.css";
// Carousel images
import img1 from "../../../../assets/images/profile-bg.jpg";
import img2 from "../../../../assets/images/profile-bg2.jpg";
import img3 from "../../../../assets/images/profile-bg3.jpg";
const Slide = () => {
  return (
    <div className="black-bg">
      <UncontrolledCarousel
        controls={false}
        indicators={false}
        interval={3000}
        items={[
          {
            altText: " ",
            caption: " ",
            key: 1,
            src: img1,
            className: "img-slider",
          },
          {
            altText: " ",
            caption: " ",
            key: 2,
            src: img2,
            className: "img-slider",
          },
          {
            altText: " ",
            caption: " ",
            key: 3,
            src: img3,
            className: "img-slider",
          },
        ]}
      />
    </div>
  );
};

export default Slide;
