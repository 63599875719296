import { useQuery, useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { createArticle, deleteArticle, findArticle, getAllArticles, updateArticle } from "../services/articles";

export function useAddArticle () {
  const queryClient = useQueryClient();
  return useMutation((data) => createArticle(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(["articles"]);
    },
    onError: (error) => {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message, {
          position: "top-right",
          hideProgressBar: true,
          closeOnClick: false,
          className: "bg-danger text-white",
        });
      }
    },
  });
}

export function useFindArticle(id) {
  return useQuery(["articles", id], () => findArticle(id), {
    enabled: !!id,
  });
}

export function useAllArticles() {
  return useQuery(["articles"], getAllArticles);
}

export function useUpdateArticle() {
  const queryClient = useQueryClient();
  return useMutation(({ id, data }) => updateArticle(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(["articles"]);
    },
  });
}

export function useDeleteArticle() {
  const queryClient = useQueryClient();
  return useMutation(({ id }) => deleteArticle(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["articles"]);
    },
  });
}
