import React, { useContext } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";

import { useProfile } from "../Components/Hooks/UserHooks";
import { AuthContext } from "../store/AuthContext";

const AuthProtected = (props) => {
  const { user } = useContext(AuthContext);
  const { pathname } = useLocation();

  /*
    redirect is un-auth access protected routes via url
    */

  if (!user) {
    return <Redirect to={{ pathname: "/login", state: { from: pathname } }} />;
  }
  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            {" "}
            <Component {...props} />{" "}
          </>
        );
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
