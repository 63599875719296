import React, { useContext, useState, useEffect } from "react";
import { Grid, _ } from "gridjs-react";
import {
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import deDE from "../../locales/deGrid";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAllArticles } from "../../hooks/useArticles";
import { DateTime } from "luxon";

// ArticleTable Example
const ArticleTable = ({
  handleOpenModal,
  handleDeleteModal,
  actionDoots,
  deleteText,
  edit,
}) => {
  const language = localStorage.getItem("I18N_LANGUAGE");
  const { t } = useTranslation();
  const { data: articles } = useAllArticles();

  const history = useHistory();
  const data = articles;
  const [modal_standard, setmodal_standard] = useState(false);
  const tog_standard = () => {
    setmodal_standard(!modal_standard);
  };
  return (
    <React.Fragment>
      {data && (
        <Grid
          data={data}
          columns={[
            {
              name: "ID",
              data: (cell) => cell,
              width: "65px",
              formatter: (cell) =>
                _(
                  <span className="fw-semibold">
                    {data?.findIndex((d) => d._id === cell._id) + 1}
                  </span>
                ),
            },
            {
              name: "Author",
              data: (cell) => cell?.author?.fname + " " + cell.author.lname,
              formatter: (cell) =>
                _(
                  <span
                    className={
                      !cell &&
                      "fw-semibold badge badge-soft-danger text-uppercase"
                    }
                  >
                    {cell ? cell : "K.A"}
                  </span>
                ),
            },
            {
              name: "Title",
              data: (cell) => cell.title,
              formatter: (cell) =>
                _(
                  <span
                    className={
                      !cell &&
                      "fw-semibold badge badge-soft-danger text-uppercase"
                    }
                  >
                    {cell ? cell : "K.A"}
                  </span>
                ),
            },
            // {
            //   name: "Content",
            //   data: (cell) => cell.content,
            //   formatter: (cell) =>
            //     _(
            //       <span
            //         className={
            //           !cell &&
            //           "fw-semibold badge badge-soft-danger text-uppercase"
            //         }
            //       >
            //         {cell ? cell : "K.A"}
            //       </span>
            //     ),
            // },

            {
              name: "Updated",
              data: (cell) =>
                DateTime.fromISO(cell.dateUpdated).toFormat("MMM dd,yyyy"),
              formatter: (cell) =>
                _(
                  <span
                    className={
                      !cell &&
                      "fw-semibold badge badge-soft-danger text-uppercase"
                    }
                  >
                    {cell ? cell : "K.A"}
                  </span>
                ),
            },

            {
              name: "Created",
              data: (cell) =>
                DateTime.fromISO(cell.dateCreated).toFormat("MMM dd,yyyy"),
              formatter: (cell) =>
                _(
                  <span
                    className={
                      !cell &&
                      "fw-semibold badge badge-soft-danger text-uppercase"
                    }
                  >
                    {cell ? cell : "K.A"}
                  </span>
                ),
            },

            {
              name: actionDoots,
              width: "120px",
              data: (cell) => data?.find((ser) => ser._id === cell._id),
              formatter: (cell) =>
                _(
                  <UncontrolledDropdown>
                    <DropdownToggle
                      className="btn-primary btn-sm shadow-none"
                      tag="button"
                    >
                      <i className="ri-more-fill" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem onClick={() => handleOpenModal(cell?._id)}>
                        <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                        {edit}
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => handleDeleteModal(cell?._id)}
                      >
                        <i className="ri-delete-bin-line align-bottom me-2 text-muted"></i>{" "}
                        {deleteText}
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                ),
            },
          ]}
          search={true}
          sort={true}
          pagination={{ enabled: true, limit: 10 }}
          language={language === "gr" && deDE}
        />
      )}
    </React.Fragment>
  );
};

export { ArticleTable };
