import React, { useContext } from "react";
import { Button, Col, Form, Row } from "reactstrap";
import FormGroupText from "../../Common/FormGroupText";
import { useFormik } from "formik";
import * as Yup from "yup";
import { withTranslation } from "react-i18next";
import { AuthContext } from "../../../store/AuthContext";

const CompanyIframForm = ({
  company,
  toggleTab,
  validationIframe,
  handleUpdateCompany,
  ...props
}) => {
  const { user } = useContext(AuthContext);
  return (
    // <Form
    //   className="needs-validation"
    //   onSubmit={(e) => {
    //     e.preventDefault();
    //     validationIframe.handleSubmit();
    //     return false;
    //   }}
    // >
    <>
      <Row>
        <Col lg="6">
          <FormGroupText
            idInput="google_map"
            label={props.t("Location Map")}
            isRequired={true}
            attributeName="google_map"
            placeholder=""
            validation={validationIframe}
            type="text"
          />
          <span className="text-muted">{props.t("iframe help text")}</span>
        </Col>
        <Col lg="6">
          <FormGroupText
            idInput="google_iframe"
            label={props.t("Iframe")}
            isRequired={true}
            attributeName="google_iframe"
            placeholder=""
            validation={validationIframe}
            type="text"
          />
          <span className="text-muted">{props.t("location help text")}</span>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroupText
            idInput="langutitude"
            label={props.t("Latitude")}
            attributeName="langutitude"
            placeholder=""
            validation={validationIframe}
            type="text"
          />
          <span className="text-muted">{props.t("Latitude help text")}</span>
        </Col>
        <Col lg="6">
          <FormGroupText
            idInput="longitude"
            label={props.t("Longitude")}
            attributeName="longitude"
            placeholder=""
            validation={validationIframe}
            type="text"
          />
          <span className="text-muted">{props.t("Longitude help text")}</span>
        </Col>
      </Row>
      <Row className="text-end mt-3">
        <Col lg={12}>
          <Button
            className="btn btn-primary"
            type="button"
            onClick={() => toggleTab("4")}
          >
            {props.t("Next")}
          </Button>
        </Col>
      </Row>
    </>
    // </Form>
  );
};

export default withTranslation()(CompanyIframForm);
