export const countryOptions = [
  { value: "ch", label: "Switzerland" },
  { value: "de", label: "Deutschland" },
  { value: "at", label: "Österreich" },
];

export const durationOptions = [
  { value: "15", label: "15 min" },
  { value: "30", label: "30 min" },
  { value: "45", label: "45 min" },
  { value: "60", label: "60 min" },
];

export const timeOptions = [{ value: "180", label: "3H" }];

export const groupOptions = [
  { value: "1", label: "Health issues" },
  { value: "2", label: "Symptome" },
  { value: "3", label: "Behandlung" },
  { value: "4", label: "Resultate" },
  { value: "5", label: "Editing staff" },
  { value: "6", label: "More information" },
];

export const fieldOptions = [
  { value: "input", label: "Input ( Text )" },
  { value: "radio", label: "Boolean ( Yes/No )" },
  { value: "select", label: "Select" },
  { value: "number", label: "Number" },
  { value: "date", label: "Date" },
  { value: "email", label: "Email" },
  { value: "tel", label: "Phone" },
  { value: "textarea", label: "Textarea" },
];

export const columnWidth = [
  { value: "25", label: "25%" },
  { value: "33", label: "33%" },
  { value: "50", label: "50%" },
  { value: "66", label: "66%" },
  { value: "100", label: "100%" },
];

export const isActiveOptions = [
  { value: "aktiv", label: "Aktiv" },
  { value: "inaktiv", label: "Inaktiv" },
];

export const isSuperCompanyOptions = [
  { value: "", label: "Select Company Type" },
  { value: false, label: "No" },
  { value: true, label: "Yes" },
];

export const isCompanyRegisteredOptions = [
  { value: "", label: "Is Company Registered" },
  { value: false, label: "No" },
  { value: true, label: "Yes" },
];

export const categoryColors = [
  { color: "rgba(75,56,179,.18)", value: "primary", label: "" },
  { color: "rgba(53,119,241,.18)", value: "secondary", label: "" },
  { color: "rgba(69,203,133,.18)", value: "success", label: "" },
  { color: "rgba(41,156,219,.18)", value: "info", label: "" },
  { color: "rgba(255,190,11,.18)", value: "warning", label: "" },
  { color: "rgba(240,101,72,.18)", value: "danger", label: "" },
  { color: "rgba(33,37,41,.18)", value: "dark", label: "" },
  { color: "rgba(243,246,249,.18)", value: "light", label: "" },
];

export const statusOptions = [
  { value: "buchung_termin", label: "Buchung Termin" },
  { value: "check_in", label: "Check-In" },
  { value: "bearbeitung", label: "Bearbeitung" },
  { value: "abschluss", label: "Abschluss" },
  { value: "schlusskontrolle", label: "Schlusskontrolle" },
  { value: "versand_speicherung", label: "Versand Speicherung" },
];

export const YesNoOptions = [
  { value: "yes", label: "JA" },
  { value: "no", label: "NEIN" },
];

export const YesNoOptionsService = [
  { value: "1", label: "JA" },
  { value: "0", label: "NEIN" },
];

export const RequiredFieldsOptions = [
  { value: "1", label: "Gewicht" },
  { value: "2", label: "Größe" },
  { value: "3", label: "BMI" },
  { value: "4", label: "Bauchumfang" },
];

export const typeDayWork = [
  { value: "24", label: "24 H" },
  { value: "Closed", label: "Closed" },
  { value: "Custom", label: "Custom" },
];

export const dayOfTheWeek = [
  { value: "0", label: "Sunday" },
  { value: "1", label: "Monday" },
  { value: "2", label: "Tuesday" },
  { value: "3", label: "Wednesday" },
  { value: "4", label: "Thursday" },
  { value: "5", label: "Friday" },
  { value: "6", label: "Saturday" },
];

export const clockOptions = [
  { value: "1", label: "00:00" },
  { value: "2", label: "00:30" },
  { value: "3", label: "01:00" },
  { value: "4", label: "01:30" },
  { value: "5", label: "02:00" },
  { value: "6", label: "02:30" },
  { value: "7", label: "03:00" },
  { value: "8", label: "03:30" },
  { value: "9", label: "04:00" },
  { value: "10", label: "04:30" },
  { value: "11", label: "05:00" },
  { value: "12", label: "05:30" },
  { value: "13", label: "06:00" },
  { value: "14", label: "06:30" },
  { value: "15", label: "07:00" },
  { value: "16", label: "07:30" },
  { value: "17", label: "08:00" },
  { value: "18", label: "08:30" },
  { value: "19", label: "09:00" },
  { value: "20", label: "09:30" },
  { value: "21", label: "10:00" },
  { value: "22", label: "10:30" },
  { value: "23", label: "11:00" },
  { value: "24", label: "11:30" },
  { value: "25", label: "12:00" },
  { value: "26", label: "12:30" },
  { value: "27", label: "13:00" },
  { value: "28", label: "13:30" },
  { value: "29", label: "14:00" },
  { value: "30", label: "14:30" },
  { value: "31", label: "15:00" },
  { value: "32", label: "15:30" },
  { value: "33", label: "16:00" },
  { value: "34", label: "16:30" },
  { value: "35", label: "17:00" },
  { value: "36", label: "17:30" },
  { value: "37", label: "18:00" },
  { value: "38", label: "18:30" },
  { value: "39", label: "19:00" },
  { value: "40", label: "19:30" },
  { value: "41", label: "20:00" },
  { value: "42", label: "20:30" },
  { value: "43", label: "21:00" },
  { value: "44", label: "21:30" },
  { value: "45", label: "22:00" },
  { value: "46", label: "22:30" },
  { value: "47", label: "23:00" },
  { value: "48", label: "23:30" },
];
