import React, { useContext, useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//i18n
import { withTranslation } from "react-i18next";
import { useFindCompany, useUpdateCompany } from "../../hooks/useCompany";
import {
  useDeleteSchedule,
  useUpdateSchedule,
} from "../../hooks/useWorkingTIme";
import { AuthContext } from "../../store/AuthContext";
import { useGetSchedule, useCreateDay } from "../../hooks/useWorkingTIme";
import WorkingScedule from "../../Components/Settings/Company/WorkingScedule";
import Navigation from "./Components/Navigation/Navigation";
import CompanyDetailsForm from "./Components/CompanyDetailsForm";
import CompanyOwnerForm from "./Components/CompanyOwnerForm";
import CompanyIframForm from "./Components/CompanyIframForm";
import CompanySocialMediaForm from "./Components/CompanySocialMediaForm";
import { useParams } from "react-router-dom";

const Company = (props) => {
  const { user } = useContext(AuthContext);
  const params = useParams();

  const {
    mutate: editCompany,
    isSuccess: isSuccessEditCompany,
    isLoading: isEditCompany,
    isError: isErrorEditingCompany,
    error,
    editingError,
  } = useUpdateCompany();
  const {
    mutate: editSchedule,
    isSuccess: isSuccessEditSchedule,
    isLoading: isEditSchedule,
    isError: isErrorEditingSchedule,
  } = useUpdateSchedule();
  const { mutate: deleteSchedule } = useDeleteSchedule();
  const { mutate: createDay } = useCreateDay();
  const { data: companyTime } = useGetSchedule(params?.id);
  const { data: company } = useFindCompany(params?.id);
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const handleUpdateCompany = (id, data, isCoverImage) => {
    editCompany({ id: params?.id, data, isCoverImage });
  };

  const handleUpdateSchedule = (id, data) => {
    editSchedule({ id, data });
  };

  const handleAddDay = (data) => {
    createDay(data);
  };

  const handleDeleteDay = (id) => {
    deleteSchedule({ id });
  };

  useEffect(() => {
    if (isSuccessEditCompany && !isEditCompany) {
      toast(props.t("Successfully edited company details!"), {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: false,
        className: "bg-success text-white",
      });
    }
  }, [isSuccessEditCompany, isEditCompany]);

  useEffect(() => {
    if (isSuccessEditSchedule && !isEditSchedule) {
      toast(props.t("Successfully edited company working hours!"), {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: false,
        className: "bg-success text-white",
      });
    }
  }, [isSuccessEditSchedule, isEditSchedule]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <MetaTags>
            <title>
              {props.t("Company")} | {props.t("MyHealth Brend")}
            </title>
          </MetaTags>
          <BreadCrumb
            title={props.t("Company")}
            pageTitle={props.t("Settings")}
          />

          <Row>
            <Col lg={11}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">{props.t("Company")}</h4>
                </CardHeader>

                <CardBody>
                  <Navigation
                    customActiveTab={customActiveTab}
                    toggleCustom={toggleCustom}
                    props={props.t}
                  />

                  <TabContent activeTab={customActiveTab}>
                    <TabPane tabId="1" id="home1">
                      <CompanyDetailsForm
                        company={company}
                        handleUpdateCompany={handleUpdateCompany}
                      />
                    </TabPane>
                    <TabPane tabId="3">
                      <CompanyIframForm
                        company={company}
                        handleUpdateCompany={handleUpdateCompany}
                      />
                    </TabPane>

                    <TabPane tabId="4">
                      <CompanySocialMediaForm
                        company={company}
                        handleUpdateCompany={handleUpdateCompany}
                      />
                    </TabPane>

                    <TabPane tabId="5">
                      <Row>
                        <Col lg={11}>
                          <Row>
                            <Col lg={1}>
                              <b>{props.t("Day")}</b>
                            </Col>
                            <Col lg={2}>
                              <b>{props.t("Start time")}</b>
                            </Col>
                            <Col lg={2}>
                              <b>{props.t("End time")}</b>
                            </Col>
                            <Col lg={2}>
                              <b>{props.t("Pause from")}</b>
                            </Col>
                            <Col lg={2}>
                              <b>{props.t("Pause to")}</b>
                            </Col>
                            <Col lg={2}></Col>
                            <Col lg={1}></Col>
                          </Row>
                        </Col>
                      </Row>
                      <WorkingScedule
                        monday={props.t("Monday")}
                        tuesday={props.t("Tuesday")}
                        wednesday={props.t("Wednesday")}
                        thursday={props.t("Thursday")}
                        friday={props.t("Friday")}
                        saturday={props.t("Saturday")}
                        sunday={props.t("Sunday")}
                        workingTime={companyTime}
                        handleUpdateSchedule={handleUpdateSchedule}
                        handleAddDay={handleAddDay}
                        handleDeleteDay={handleDeleteDay}
                      />
                      <Row className="text-end mt-3">
                        <Col lg={12}>
                          <Button
                            className="btn btn-primary"
                            type="submit"
                            onClick={() => {
                              toast(
                                "Successfully edited company working hours!",
                                {
                                  position: "top-right",
                                  hideProgressBar: true,
                                  closeOnClick: false,
                                  className: "bg-success text-white",
                                }
                              );
                            }}
                          >
                            {props.t("Update")}
                          </Button>
                        </Col>
                      </Row>
                      {/* </Form> */}
                    </TabPane>

                    <TabPane tabId="6">
                      <Row>
                        <Col log={12}>
                          <Alert color="info" className="shadow">
                            {props.t("Bank Account Info Alert")}
                          </Alert>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(Company);
