import React from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";

const ImageInput = ({ label, multiple = true, isRequired, name, onChange, ...props }) => {
  return (
    <FormGroup>
      {label !== undefined && (
        <Label htmlFor={name}>
          {label} {isRequired && <span className="text-danger">*</span>}
        </Label>
      )}
      <Input
        name={name}
        className="form-control"
        onChange={onChange}
        type="file"
        {...props}
        multiple = {multiple}
      />
    </FormGroup>
  );
};

export default ImageInput;
