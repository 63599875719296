import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { withTranslation } from "react-i18next";
import { Alert, Button, Col, Form, Input, Label, Row } from "reactstrap";

import { AuthContext } from "../../../store/AuthContext";
import FormGroupText from "../../../Components/Common/FormGroupText";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import CustomSelect from "../../../Components/Common/CustomSelect";
import {
  countryOptions,
  isActiveOptions,
  isCompanyRegisteredOptions,
  isSuperCompanyOptions,
} from "../../../data/selectOptions";
import ImageInput from "../../../Components/Common/ImageInput";
import useCompanyTypes from "../../../hooks/useCompanyType";
import { useCompanyImages, useCompanyLogo } from "../../../hooks/useCompany";
import { Editor } from "../../../Components/Editor/editor";

const CompanyDetailsForm = ({ company, handleUpdateCompany, ...props }) => {
  const { user } = useContext(AuthContext);
  const [logo, setLogo] = useState([]);
  const [coverImages, setCoverImages] = useState([]);
  const [isCoverImage, setIsCoverImage] = useState(false);

  const { data: companyTypes } = useCompanyTypes();
  const { data: companyImages } = useCompanyImages(company?._id);

  const { data: companyLogo } = useCompanyLogo(company?._id);
  const [companyCategories, setCompanyCategories] = useState([]);

  useEffect(() => {
    if (coverImages.length > 0) {
      setIsCoverImage(true);
    }
  }, [coverImages]);

  useEffect(() => {
    companyTypes?.map(
      (companyType) =>
        companyType.is_active === "aktiv" &&
        setCompanyCategories((companyCategories) => [
          ...companyCategories,
          {
            label: companyType.label,
            value: companyType.label,
          },
        ])
    );
  }, [companyTypes]);

  // const companyCategories = [
  //   {
  //     label: props.t("Pharmacy"),
  //     value: "pharmacy",
  //   },
  //   {
  //     label: props.t("Doctor"),
  //     value: "doctor",
  //   },
  //   {
  //     label: props.t("Dentist"),
  //     value: "dentist",
  //   },
  // ];

  const [error, setError] = useState("");
  const [agbError, setAgbError] = useState("");
  const [contentValue, setContentValue] = useState();
  const [agbValue, setAgbValue] = useState("");
  const [phoneValue, setPhoneValue] = useState();  

  useEffect(() => {
    if (company?.phone) {
      setPhoneValue(company.phone);
    } else {
      setPhoneValue("");
    }
  }, [company]);  

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      company_name: company?.company_name || "",
      about_company: company?.about_company || "",
      is_super_company: company?.is_super_company || false,
      isCompanyRegistered: company?.isCompanyRegistered || false,
      uID: company?.uID || "",
      subCompaniesAllowed: company?.subCompaniesAllowed || "",
      agb: company?.agb || "",
      phone: company?.phone || "",
      fax: company?.fax || "",
      email: company?.email || "",
      website: company?.website || "",
      konkordac: company?.konkordac || "",
      google_review_link: company?.google_review_link || "",
      gln_number: company?.gln_number || "",
      address: company?.address || "",
      address_no: company?.address_no || "",
      marketing: company?.marketing || "",
      country: company?.country || "ch",
      city: company?.city || "",
      zip: company?.zip || "",
      is_active: company?.is_active,
      company_type: company?.company_type || ["pharmacy"],
    },

    validationSchema: Yup.object({
      company_name: Yup.string().required(props.t("Please Enter Company Name")),
      company_type: Yup.array().required(props.t("Please Enter Company Type")),
      is_super_company: Yup.string().required(
        props.t("Please Select Company Type")
      ),
      isCompanyRegistered: Yup.string().required(
        props.t("Select Company Registration Status")
      ),
      uID: Yup.string().test(
        "uID-validation",
        props.t("Please enter uID of company"),
        function (value) {
          if (this.parent.isCompanyRegistered !== "true") return true;
          if (!value) return false;
          const uidPattern = /^CHE-\d{3}\.\d{3}\.\d{3}$/;
          if (!uidPattern.test(value)) {
            return this.createError({
              path: "uID",
              message: props.t("UID Nr must follow the pattern CHE-123.456.789"),
            });
          }
          return true;
        }
      ),
      subCompaniesAllowed: Yup.number()
        .transform((value, originalValue) => {
          return originalValue === "" ? undefined : value;
        })
        .test(
          "sub-companies-validation",
          props.t("Please enter number of sub companies"),
          function (value) {
            if (this.parent.is_super_company !== "true") return true;
            if (!value) return false;
            if (value <= 0) {
              return this.createError({
                path: "subCompaniesAllowed",
                message: props.t("Sub companies value must be greater than 0"),
              });
            }
            if (typeof value !== "number") {
              return this.createError({
                path: "subCompaniesAllowed",
                message: props.t("Sub companies value must be a number"),
              });
            }
            return true;
          }
        ),
      google_review_link: Yup.string().required(
        props.t("Please Enter Google Review URL")
      ),
      about_company: Yup.string().test(
        "about-company-validation",
        props.t("Please Enter About Company"),
        function (value) {
          if (!contentValue) return setError(props.t("Please Enter About Company"));
          return true;
        }
      ),
      agb: Yup.string().test(
        "agb-validation",
        props.t("Please Enter Allgemeine Geschäftsbedingungen"),
        function (value) {
          if (!agbValue) return setAgbError(props.t("Please Enter Allgemeine Geschäftsbedingungen"));
          return true;
        }
      ),
      marketing: Yup.string().required(props.t("Please Enter Marketing")),
      phone: Yup.string().required(props.t("Please Enter Phone Number")),
      email: Yup.string()
        .email()
        .required(props.t("Please Enter Email Address")),
      address: Yup.string().required(props.t("Please Enter Address")),
      zip: Yup.string().required(props.t("Please Enter Zip")),
      is_active: Yup.string().oneOf(
        isActiveOptions.map((item) => item.value),
        props.t("You must select at least one of the options") +
          isActiveOptions.map((item) => item.label)
      ),
      city: Yup.string().required(props.t("Please Enter City")),
    }),
    onSubmit: (values) => {
      if (!contentValue || contentValue === "<p><br></p>")
        return setError(props.t("Please Enter About Company"));

      if (!agbValue || agbValue === "<p><br></p>")
        return setAgbError(props.t("Please Enter Allgemeine Geschäftsbedingungen"));
      let myFormData = new FormData();
      Object.keys(values).forEach((val) => {
        if (val === "company_type") {
          values[val].forEach((el, i) =>
            myFormData.append(val + "[]", values[val][i])
          );
        } else {
          myFormData.append(val, values[val]);
        }
      });
      if (logo.length > 0) {
        myFormData.append("logo", logo[0], logo[0].name);
      }
      if (coverImages.length > 0) {
        for (let i = 0; i < coverImages.length; i++) {
          myFormData.append("slider", coverImages[i]);
        }
      }
      myFormData.set("about_company", contentValue);
      myFormData.set("agb", agbValue);
      handleUpdateCompany(user.companyId, myFormData, isCoverImage);
    },
  });

  const countryOptions = [
    { value: "ch", label: props.t("Switzerland") },
    { value: "de", label: props.t("Deutschland") },
    { value: "at", label: props.t("Österreich") },
  ];

  useEffect(() => {
    if (company?.agb) {
      setAgbValue(company?.agb)
    }
    if (company?.about_company) {
      setContentValue(company?.about_company)
    }
  }, [company])
  
  useEffect(() => {
    if (contentValue) {
      setError("")
    }
    if (agbValue) {
      setAgbError("")
    }
  }, [contentValue, agbValue]);

  useEffect(() => {
    setContentValue(company?.about_company);
    setAgbValue(company?.agb);
  }, [company?.about_company, company?.agb]);

  useEffect(() => {
    if (phoneValue) {
      validation?.setFieldValue("phone", phoneValue);
    } else {
      validation?.setFieldValue("phone", "");
    }
  }, [phoneValue]);  

  return (
    <Form
      className="needs-validation"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <Row>
        <Col log={12}>
          <Alert color="info" className="shadow">
            {props.t("Company Info Alert")}
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <ImageInput
            label={props.t("Logo")}
            isRequired={false}
            name="logo"
            multiple={false}
            accept="image/*"
            onChange={(e) => {
              setLogo(e.target.files);
            }}
          />
        </Col>
        <Col lg={6}>
          <ImageInput
            label={props.t("Cover image")}
            isRequired={false}
            name="slider"
            accept="image/*"
            onChange={(e) => {
              setCoverImages(e.target.files);
            }}
            multiple={false}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={validation.values["isCompanyRegistered"] ? "6" : "12"}>
          <CustomSelect
            label={props.t("Is Company Registered")}
            isRequired={true}
            onChange={(value) =>
              validation.setFieldValue("isCompanyRegistered", value.value)
            }
            selectOptions={isCompanyRegisteredOptions}
            selectValues={validation.values["isCompanyRegistered"]}
            styles={
              validation.touched["isCompanyRegistered"] &&
              validation.errors["isCompanyRegistered"] && {
                container: (base) => ({
                  ...base,
                  backgroundColor: "#e74c3c",
                  padding: "0.8px",
                  borderRadius: "5px",
                }),
              }
            }
          >
            {validation.touched["isCompanyRegistered"] &&
              validation.errors["isCompanyRegistered"] && (
                <p
                  style={{
                    color: "#f06548",
                    marginTop: "0.25rem",
                    fontSize: "0.875em",
                  }}
                >
                  {validation.errors["isCompanyRegistered"]}
                </p>
              )}
          </CustomSelect>
        </Col>
        {validation.values["isCompanyRegistered"] && (
          <Col lg="6">
            <FormGroupText
              idInput="uID"
              label={props.t("UID Nr")}
              type="text"
              isRequired={true}
              attributeName="uID"
              placeholder=""
              validation={validation}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col lg={validation.values["is_super_company"] ? "6" : "12"}>
          <CustomSelect
            label={props.t("Is Super Company")}
            isRequired={true}
            onChange={(value) =>
              validation.setFieldValue("is_super_company", value.value)
            }
            selectOptions={isSuperCompanyOptions}
            selectValues={validation.values["is_super_company"]}
            styles={
              validation.touched["is_super_company"] &&
              validation.errors["is_super_company"] && {
                container: (base) => ({
                  ...base,
                  backgroundColor: "#e74c3c",
                  padding: "0.8px",
                  borderRadius: "5px",
                }),
              }
            }
          >
            {validation.touched["is_super_company"] &&
              validation.errors["is_super_company"] && (
                <p
                  style={{
                    color: "#f06548",
                    marginTop: "0.25rem",
                    fontSize: "0.875em",
                  }}
                >
                  {validation.errors["is_super_company"]}
                </p>
              )}
          </CustomSelect>
        </Col>
        {validation.values["is_super_company"] && (
          <Col lg="6">
            <FormGroupText
              idInput="subCompaniesAllowed"
              label={props.t("Number of sub companies allowed")}
              type="number"
              isRequired={true}
              attributeName="subCompaniesAllowed"
              placeholder=""
              validation={validation}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col lg="12">
          <CustomSelect
            label={props.t("Company category")}
            isRequired={true}
            onChange={(option) =>
              validation.setFieldValue(
                "company_type",
                option.map((item) => item.value)
              )
            }
            selectOptions={companyCategories}
            selectValues={validation.values.company_type}
            isMulti={true}
            styles={
              validation.touched.department &&
              validation.errors.department && {
                container: (base) => ({
                  ...base,
                  backgroundColor: "#e74c3c",
                  padding: "0.8px",
                  borderRadius: "5px",
                }),
              }
            }
          >
            {validation.touched.department && validation.errors.department && (
              <p
                style={{
                  color: "#f06548",
                  marginTop: "0.25rem",
                  fontSize: "0.875em",
                }}
              >
                {validation.errors.department}
              </p>
            )}
          </CustomSelect>
        </Col>
      </Row>
      <Row>
        <label className="form-label">
          Allow Appointments <span className="text-danger">*</span>
        </label>
        <div>
          <div className="form-check form-check-inline">
            <Input
              checked={validation?.values?.marketing === "1" && "true"}
              className="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="1"
              onChange={(e) =>
                validation.setFieldValue("marketing", e.target.value)
              }
              value="1"
            />
            <label className="form-check-label" htmlFor="1">
              {props.t("true")}
            </label>
          </div>
          <div className="form-check form-check-inline">
            <Input
              checked={validation?.values?.marketing === "0" && "true"}
              className="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="0"
              onChange={(e) =>
                validation.setFieldValue("marketing", e.target.value)
              }
              value="0"
            />
            <label className="form-check-label" htmlFor="0">
              {props.t("false")}
            </label>
          </div>
        </div>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col lg="12">
          <FormGroupText
            idInput="company_name"
            label={props.t("Company Name")}
            isRequired={true}
            attributeName="company_name"
            validation={validation}
            type="text"
            rows={3}
          />
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Label htmlFor="about_company">
            {props.t("About Company")} <span className="text-danger">*</span>
          </Label>
          <Editor
            name="about_company"
            value={contentValue}
            setValue={setContentValue}
          />
          {error && (
            <p
              style={{
                color: "#f06548",
                marginTop: "0.25rem",
                fontSize: "0.875em",
              }}
            >
              {error}
            </p>
          )}
        </Col>
      </Row>

      <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
        <Col lg="12">
          <Label htmlFor="agb">
            {props.t("Allgemeine Geschäftsbedingungen")}{" "}
            <span className="text-danger">*</span>
          </Label>
          <Editor name="agb" value={agbValue} setValue={setAgbValue} />
          {agbError && (
            <p
              style={{
                color: "#f06548",
                marginTop: "0.25rem",
                fontSize: "0.875em",
              }}
            >
              {agbError}
            </p>
          )}
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <div style={{ marginBottom: "1rem" }}>
            <Label htmlFor="phone">
              {"Phone"} {<span className="text-danger">*</span>}
            </Label>
            <PhoneInput
              style={{ border: "1px solid #ced4da" }}
              numberInputProps={{
                className: "phone-input",
              }}
              name="phone"
              id="phone"
              placeholder="Enter phone number"
              defaultCountry="CH"
              value={phoneValue}
              onChange={setPhoneValue}
            />
            {validation.touched.phone && validation.errors.phone && (
              <p
                style={{
                  color: "#f06548",
                  marginTop: "0.25rem",
                  fontSize: "0.875em",
                }}
              >
                {validation.errors.phone}
              </p>
            )}
          </div>
        </Col>
        <Col lg="6">
          <FormGroupText
            idInput="fax"
            label={props.t("Phone Two")}
            attributeName="fax"
            placeholder=""
            validation={validation}
            type="text"
          />
        </Col>
      </Row>

      <Row>
        <Col lg="6">
          <FormGroupText
            idInput="email"
            label={props.t("Email One")}
            isRequired={true}
            attributeName="email"
            placeholder=""
            validation={validation}
            type="email"
          />
        </Col>
        <Col lg="6">
          <FormGroupText
            idInput="website"
            label={props.t("Website")}
            attributeName="website"
            placeholder=""
            validation={validation}
            type="text"
          />
        </Col>
      </Row>

      <Row>
        <Col lg="6">
          <FormGroupText
            idInput="konkordac"
            label={props.t("Konkordac")}
            attributeName="konkordac"
            placeholder=""
            validation={validation}
            type="text"
          />
        </Col>
        <Col lg="6">
          <FormGroupText
            idInput="gln_number"
            label={props.t("GlnNumber")}
            attributeName="gln_number"
            placeholder=""
            validation={validation}
            type="text"
          />
        </Col>
      </Row>

      <Row>
        <Col lg="4">
          <FormGroupText
            idInput="google_review_link"
            label={props.t("Google Review URL")}
            isRequired={true}
            attributeName="google_review_link"
            placeholder=""
            validation={validation}
            type="text"
          />
        </Col>
        <Col lg="4">
          <FormGroupText
            idInput="address"
            label={props.t("Address")}
            isRequired={true}
            attributeName="address"
            placeholder=""
            validation={validation}
            type="text"
          />
        </Col>
        <Col lg="4">
          <FormGroupText
            idInput="address_no"
            label={props.t("AddressNo")}
            attributeName="address_no"
            placeholder=""
            validation={validation}
            type="text"
          />
        </Col>
      </Row>

      <Row>
        <Col md="4">
          <FormGroupText
            idInput="zip"
            label={props.t("Zip")}
            isRequired={true}
            attributeName="zip"
            placeholder=""
            validation={validation}
            type="text"
          />
        </Col>
        <Col md="4">
          <FormGroupText
            idInput="city"
            label={props.t("City")}
            isRequired={true}
            attributeName="city"
            placeholder=""
            validation={validation}
            type="text"
          />
        </Col>
        <Col md="4">
          <CustomSelect
            label={props.t("Country")}
            onChange={(value) =>
              validation.setFieldValue("country", value.value)
            }
            selectOptions={countryOptions}
            selectValues={validation.values.country}
          />
        </Col>
      </Row>

      <Row>
        <Col lg={12}>
          <CustomSelect
            label={props.t("Status")}
            onChange={(value) =>
              validation.setFieldValue("is_active", value.value)
            }
            selectOptions={isActiveOptions}
            selectValues={validation.values.is_active}
          />
        </Col>
      </Row>

      <Row className="text-end mt-3">
        <Col lg={12}>
          <Button className="btn btn-primary" type="submit">
            {props.t("Update")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default withTranslation()(CompanyDetailsForm);
