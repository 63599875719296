import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  createCompanyType,
  deleteCompanyType,
  findCompanyType,
  getAllCompanyTypes,
  updateCompanyType,
} from "../services/companyTypes";

export default function useCompanyTypes() {
  return useQuery(["company-types"], getAllCompanyTypes);
}

export function useAddCompanyType() {
  const queryClient = useQueryClient();
  return useMutation(createCompanyType, {
    onSuccess: () => {
      queryClient.invalidateQueries(["company-types"]);
    },
  });
}

export function useFindCompanyType(id) {
  return useQuery(["company-types", id], () => findCompanyType(id), {
    enabled: !!id,
  });
}

export function useUpdateCompanyType(id) {
  const queryClient = useQueryClient();
  return useMutation(({ id, data }) => updateCompanyType(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(["company-types"]);
    },
  });
}

export function useDeleteCompanyType() {
  const queryClient = useQueryClient();
  return useMutation(({ id }) => deleteCompanyType(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["company-types"]);
    },
  });
}
