import React, { useContext, useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

//import images
import avatar1 from "../../assets/images/users/avatar-8.jpg";

//i18n
import { withTranslation } from "react-i18next";
import { AuthContext } from "../../store/AuthContext";
import Initals from "../Initals";

const ProfileDropdown = (props) => {
  const { logout, user } = useContext(AuthContext);
  const [isAdmin, setIsAdmin] = useState(false);
  //Dropdown Toggle
  useEffect(() => {
    user?.roles?.forEach((element) => {
      element?.includes("ADMIN") && setIsAdmin(true);
    });
  }, [user]);
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };
  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-3 header-item topbar-user"
      >
        <DropdownToggle tag="button" type="button" className="btn shadow-none">
          <span className="d-flex align-items-center">
            <Initals fname={user?.fname} lname={user?.lname} />
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                {`${user?.fname} ${user?.lname}`}
              </span>
              <span className="badge bg-soft-success text-success mt-1 d-none d-xl-block ms-1 fs-12 user-name-sub-text">
                {(isAdmin && props.t("Administrator")) || "Employee"}
              </span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <h6 className="dropdown-header">{props.t("Welcome")}</h6>
          {/* <DropdownItem href="/pages-profile"><i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                        <span className="align-middle">{props.t("Profile")}</span></DropdownItem> */}
          <DropdownItem href="/settings">
            <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">{props.t("Settings")}</span>
          </DropdownItem>
          <div className="dropdown-divider"></div>

          <DropdownItem onClick={logout}>
            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle" data-key="t-logout">
              {props.t("Logout")}
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(ProfileDropdown);
