import React, { useContext } from "react";
import { Button, Col, Form, Row } from "reactstrap";
import FormGroupText from "../../../Components/Common/FormGroupText";
import { useFormik } from "formik";
import * as Yup from "yup";
import { withTranslation } from "react-i18next";
import { AuthContext } from "../../../store/AuthContext";

const CompanyIframForm = ({ company, handleUpdateCompany, ...props }) => {
  const { user } = useContext(AuthContext);
  const validationIframe = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      google_map: company?.google_map || "",
      google_iframe: company?.google_iframe || "",
      longitude: company?.longitude || "",
      langutitude: company?.langutitude || "",
    },
    validationSchema: Yup.object({
      google_map: Yup.string().required(
        props.t("Please Enter Location of Google Maps")
      ),
      google_iframe: Yup.string().required(
        props.t("Please Enter iFrame of Google Maps")
      ),
    }),
    onSubmit: (values) => {
      handleUpdateCompany(user.companyId, values);
    },
  });
  return (
    <Form
      className="needs-validation"
      onSubmit={(e) => {
        e.preventDefault();
        validationIframe.handleSubmit();
        return false;
      }}
    >
      <Row>
        <Col lg="6">
          <FormGroupText
            idInput="google_map"
            label={props.t("Location Map")}
            isRequired={true}
            attributeName="google_map"
            placeholder=""
            validation={validationIframe}
            type="text"
          />
          <span className="text-muted">{props.t("iframe help text")}</span>
        </Col>
        <Col lg="6">
          <FormGroupText
            idInput="google_iframe"
            label={props.t("Iframe")}
            isRequired={true}
            attributeName="google_iframe"
            placeholder=""
            validation={validationIframe}
            type="text"
          />
          <span className="text-muted">{props.t("location help text")}</span>
        </Col>
      </Row>

      <Row>
        <Col lg="6">
          <FormGroupText
            idInput="langutitude"
            label={props.t("Latitude")}
            isRequired={true}
            attributeName="langutitude"
            placeholder=""
            validation={validationIframe}
            type="text"
          />
          <span className="text-muted">{props.t("Latitude help text")}</span>
        </Col>
        <Col lg="6">
          <FormGroupText
            idInput="longitude"
            label={props.t("Longitude ")}
            isRequired={true}
            attributeName="longitude"
            placeholder=""
            validation={validationIframe}
            type="text"
          />
          <span className="text-muted">{props.t("Longitude help text")}</span>
        </Col>
      </Row>

      <Row className="text-end mt-3">
        <Col lg={12}>
          <Button className="btn btn-primary" type="submit">
            {props.t("Update")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default withTranslation()(CompanyIframForm);
