import React from "react";
import { Redirect } from "react-router-dom";

//pages
import Cover404 from "../pages/AuthenticationInner/Errors/Cover404";
import Error500 from "../pages/AuthenticationInner/Errors/Error500";

//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

// Dashboard MyHealth

// Setting
import NewCompany from "../pages/NewCompany";
import Company from "../pages/Company";
import CompanyTypes from "../pages/CompanyTypes";
import User from "../pages/User";
import Employee from "../pages/Employee";
import ResetPassword from "../pages/Authentication/ResetPassword";
import EditCompany from "../pages/EditCompany";
import Articles from "../pages/Articles";
import Settings from "../pages/Settings";

const authProtectedRoutes = [
  //MyHealt Route

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name

  //Company

  { path: "/company", component: Company },
  { path: "/new-company", component: NewCompany },
  { path: "/edit-company/:id", component: EditCompany },
  { path: "/user", component: User },
  { path: "/employee", component: Employee },
  { path: "/company-types", component: CompanyTypes },
  { path: "/articles", component: Articles },
  { path: "/settings", component: Settings },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/company" />,
  },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPasswordPage },
  { path: "/reset-password", component: ResetPassword },
  { path: "/register", component: Register },

  //AuthenticationInner pages
  { path: "/auth-404-cover", component: Cover404 },
  { path: "/auth-500", component: Error500 },
];

export { authProtectedRoutes, publicRoutes };
