import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../store/AuthContext";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
  Spinner,
} from "reactstrap";
import Slide from "../../Components/Common/UiCarousel/CarouselTypes/slide";
import { ReactComponent as PoweredByIcon } from "../../assets/images/powered-by.svg";

import { Link, useHistory, useLocation, withRouter } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
//i18n
import { withTranslation } from "react-i18next";

import logoLight from "../../assets/images/logo_dark.png";
//Import config
import LanguageDropdown from "../../Components/Common/LanguageDropdown";
import useVerifyForgotPassword from "../../hooks/useVerifyForgotPassword";
//import images

const Login = (props) => {
  const { push } = useHistory();
  const { search } = useLocation();
  const { email, code } = Object.fromEntries(new URLSearchParams(search));
  const {
    mutate: resetPassword,
    isSuccess: successfullyResetPassword,
    isLoading: isResetingPassword,
    isFetching: isFetchingResetPassword,
    data: resetPasswordData,
    error: resetPasswordError,
  } = useVerifyForgotPassword();
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email,
      otp: code,
      new_password: "",
      passwordConfirmation: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required(props.t("username_validation")),
      otp: Yup.string().required(),
      new_password: Yup.string().min(6).required(),
      passwordConfirmation: Yup.string().oneOf(
        [Yup.ref("new_password"), null],
        "Passwords must match"
      ),
    }),
    onSubmit: (values) => {
      let data = values;
      delete data.passwordConfirmation;
      resetPassword({ data });
    },
  });

  return (
    <div className="auth-page-wrapper" style={{ height: "100vh" }}>
      <div className="auth-page-content">
        <MetaTags>
          <title>{props.t("MyHealth Brend")}</title>
        </MetaTags>
        <Container fluid>
          <Row style={{ height: "100vh" }}>
            <Col md={12} lg={6} xl={6} className="g-0">
              <Row style={{ backgroundColor: "white", paddingLeft: "10px" }}>
                <Col md={1}>
                  <LanguageDropdown />
                </Col>
                <Col md={11}>
                  <Card
                    className="m-0 w-100"
                    style={{
                      height: "100vh",
                      display: "flex",
                      boxShadow: "none",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CardBody>
                      {!email && !code && (
                        <div style={{ marginLeft: "15%", marginRight: "15%" }}>
                          <div className="text-center mt-2">
                            <h5 className="text-primary">
                              {props.t("Forgot password")}?
                            </h5>
                            <p className="text-muted">
                              {props.t("Reset password with email")}
                            </p>
                          </div>

                          <Alert
                            className="alert-borderless alert-danger text-center mb-2 mx-2"
                            role="alert"
                          >
                            Invalid link
                          </Alert>
                          <div className="float-end">
                            <Link to="/login" className="text-muted">
                              Back to login?
                            </Link>
                          </div>
                        </div>
                      )}
                      {email && code && (
                        <div style={{ marginLeft: "15%", marginRight: "15%" }}>
                          <div className="text-center mt-2">
                            <h5 className="text-primary">
                              {props.t("Forgot password")}?
                            </h5>
                            <p className="text-muted">
                              {props.t("Reset password with email")}
                            </p>
                          </div>

                          <Alert
                            className="alert-borderless alert-warning text-center mb-2 mx-2"
                            role="alert"
                          >
                            Enetr your new password
                          </Alert>
                          <div className="p-2">
                            {resetPasswordError && (
                              <Alert
                                color="danger"
                                style={{ marginTop: "13px" }}
                              >
                                {resetPasswordError?.response?.data?.message}
                              </Alert>
                            )}
                            {resetPasswordData && (
                              <Alert
                                color="success"
                                style={{ marginTop: "13px" }}
                              >
                                {resetPasswordData?.message}
                              </Alert>
                            )}
                            <Form
                              onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                              }}
                            >
                              <div className="mb-4">
                                <Label className="form-label">
                                  {props.t("Password")}
                                </Label>
                                <Input
                                  name="new_password"
                                  className="form-control"
                                  placeholder={props.t("Password")}
                                  type="password"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.new_password || ""}
                                  invalid={
                                    validation.touched.new_password &&
                                    validation.errors.new_password
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.new_password &&
                                validation.errors.new_password ? (
                                  <FormFeedback type="invalid">
                                    <div>{validation.errors.new_password}</div>
                                  </FormFeedback>
                                ) : null}
                              </div>

                              <div className="mb-4">
                                <Label className="form-label">
                                  Confirm Password
                                </Label>
                                <Input
                                  name="passwordConfirmation"
                                  className="form-control"
                                  placeholder="Confirm Password"
                                  type="password"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.passwordConfirmation || ""
                                  }
                                  invalid={
                                    validation.touched.passwordConfirmation &&
                                    validation.errors.passwordConfirmation
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.passwordConfirmation &&
                                validation.errors.passwordConfirmation ? (
                                  <FormFeedback type="invalid">
                                    <div>
                                      {validation.errors.passwordConfirmation}
                                    </div>
                                  </FormFeedback>
                                ) : null}
                              </div>

                              <div className="text-center mt-4">
                                <button
                                  disabled={
                                    isResetingPassword ||
                                    isFetchingResetPassword
                                  }
                                  className="btn btn-success w-100"
                                  type="submit"
                                >
                                  {isResetingPassword ||
                                  isFetchingResetPassword ? (
                                    <Spinner />
                                  ) : (
                                    "Reset password"
                                  )}
                                </button>
                              </div>
                              <div className="float-end mt-1">
                                <Link to="/login" className="text-muted">
                                  Back to login?
                                </Link>
                              </div>
                            </Form>
                          </div>
                        </div>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col md={12} lg={6} xl={6} className="g-0">
              <Slide />
            </Col>
          </Row>
          <PoweredByIcon
            onClick={() =>
              window.open("https://resolut-technologies.ch/", "_blank")
            }
            style={{
              position: "fixed",
              cursor: "pointer",
              left: "30px",
              bottom: "20px",
            }}
          />
        </Container>
      </div>
    </div>
  );
};

export default withRouter(withTranslation()(Login));
