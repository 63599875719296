import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import DeleteModal from "../../Components/Common/DeleteModal";
import MetaTags from "react-meta-tags";
//i18n
import { withTranslation } from "react-i18next";
import { CompaniesTable } from "./GridTablesData";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useDeleteCompanyType } from "../../hooks/useCompanyType";
import { useAllCompanies, useDeleteCompany, useFindCompany, useUpdateStatus } from "../../hooks/useCompany";

const Company = (props) => {
  const history = useHistory();
  const [selectedCompany, setSelectedCompany] = useState(null)

  const { data: companies, isLoading, isFetching } = useAllCompanies();

  const {
    mutate: updateStatus,
    isSuccess: isSuccessUpdateStatus,
    isLoading: isUpdatingStatus,
  } = useUpdateStatus();

  const handleDeleteModal = (id) => {
    setSelectedCompany(id);
    tog_delete();
  };

  const [delete_modal, setdelete_modal] = useState(false);

  function tog_delete() {
    setdelete_modal(!delete_modal);
  }

  const deleteModal = () => {
    const company = companies?.find(company => company._id ===  selectedCompany)
    updateStatus({id: selectedCompany, data: company.is_active === "aktiv" ? "inaktiv" : "aktiv" })
  };

  useEffect(() => {
    if (isSuccessUpdateStatus && !isUpdatingStatus) {
      tog_delete();
      setSelectedCompany(null);
    }
    if (isSuccessUpdateStatus && !isUpdatingStatus) {
      toast(props.t("Successfully updated Status!"), {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: false,
        className: "bg-danger text-white",
      });
    }
  }, [isSuccessUpdateStatus, isUpdatingStatus]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <MetaTags>
            <title>
              {props.t("Company")} | {props.t("MyHealth Brend")}
            </title>
          </MetaTags>
          <BreadCrumb
            title={props.t("Company")}
            pageTitle={props.t("Company")}
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="align-items-center d-flex border-bottom border-3 border-primary">
                  <h4 className="card-title mb-0 flex-grow-1">
                    {props.t("Company")}
                  </h4>
                  <div className="flex-shrink-0">
                    <div className="form-check form-switch form-switch-right form-switch-md">
                      <Button
                        className="btn btn-danger"
                        onClick={() => history.push(`/new-company`)}
                      >
                        <i className="ri-add-fill label-icon align-middle me-2"></i>
                        {props.t("Add_Company")}
                      </Button>
                    </div>
                  </div>
                </CardHeader>

                <CardBody>
                  <div id="table-gridjs">
                    <CompaniesTable
                     handleDeleteModal={handleDeleteModal}
                     deleteText={props.t("Update Status")}
                      actionDoots={props.t("Action doots")}
                      edit={props.t("Edit")}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <DeleteModal
        show={delete_modal}
        isActive={companies?.find(company => company._id === selectedCompany)?.is_active === "aktiv"}
        isUpdate={true}
        onCloseClick={tog_delete}
        onDeleteClick={deleteModal}
        isDeleteModal={isUpdatingStatus}
      />
    </React.Fragment>
  );
};

export default withTranslation()(Company);
