import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownMenu, DropdownToggle, Form } from 'reactstrap';

//import images
import logoSm from '../../assets/images/logo-sm.png';
import logoDark from '../../assets/images/logo-dark.png';
import logoLight from '../../assets/images/logo-light.png';
import appointoGreen from '../../assets/images/appointo-green.svg'
import appointoWhite from '../../assets/images/appointo-white.svg'

//import Components
import LanguageDropdown from '../../Components/Common/LanguageDropdown';
import FullScreenDropdown from '../../Components/Common/FullScreenDropdown';
import ProfileDropdown from '../../Components/Common/ProfileDropdown';
import LightDark from '../../Components/Common/LightDark';

const Header = ({ onChangeLayoutMode, layoutModeType }) => {
    const toogleMenuBtn = () => {
        var windowSize = document.documentElement.clientWidth;

        if (windowSize > 767)
            document.querySelector('.hamburger-icon').classList.toggle('open');

        //For collapse horizontal menu
        if (
            document.documentElement.getAttribute('data-layout') ===
            'horizontal'
        ) {
            document.body.classList.contains('menu')
                ? document.body.classList.remove('menu')
                : document.body.classList.add('menu');
        }

        //For collapse vertical menu
        if (
            document.documentElement.getAttribute('data-layout') === 'vertical'
        ) {
            if (windowSize < 1025 && windowSize > 767) {
                document.body.classList.remove('vertical-sidebar-enable');
                document.documentElement.getAttribute('data-sidebar-size') ===
                'sm'
                    ? document.documentElement.setAttribute(
                          'data-sidebar-size',
                          '',
                      )
                    : document.documentElement.setAttribute(
                          'data-sidebar-size',
                          'sm',
                      );
            } else if (windowSize > 1025) {
                document.body.classList.remove('vertical-sidebar-enable');
                document.documentElement.getAttribute('data-sidebar-size') ===
                'lg'
                    ? document.documentElement.setAttribute(
                          'data-sidebar-size',
                          'sm',
                      )
                    : document.documentElement.setAttribute(
                          'data-sidebar-size',
                          'lg',
                      );
            } else if (windowSize <= 767) {
                document.body.classList.add('vertical-sidebar-enable');
                document.documentElement.setAttribute(
                    'data-sidebar-size',
                    'lg',
                );
            }
        }

        //Two column menu
        if (
            document.documentElement.getAttribute('data-layout') === 'twocolumn'
        ) {
            document.body.classList.contains('twocolumn-panel')
                ? document.body.classList.remove('twocolumn-panel')
                : document.body.classList.add('twocolumn-panel');
        }
    };
    return (
        <React.Fragment>
            <header id="page-topbar">
                <div className="layout-width">
                    <div className="navbar-header">
                        <div className="d-flex">
                            <div className="navbar-brand-box horizontal-logo">
                                <Link to="/" className="logo logo-dark">
                                    <span className="logo-sm">
                                        <img src={logoSm} alt="" height="33" />
                                    </span>
                                    <span className="logo-lg">
                                        <img
                                            src={appointoGreen}
                                            alt=""
                                            height="17"
                                        />
                                    </span>
                                </Link>

                                <Link to="/" className="logo logo-light">
                                    <span className="logo-sm">
                                        <img src={logoSm} alt="" height="33" />
                                    </span>
                                    <span className="logo-lg">
                                        <img
                                            src={appointoGreen}
                                            alt=""
                                            height="17"
                                        />
                                    </span>
                                </Link>
                            </div>

                            <button
                                onClick={toogleMenuBtn}
                                type="button"
                                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger shadow-none"
                                id="topnav-hamburger-icon"
                            >
                                <span className="hamburger-icon">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                            </button>
                        </div>

                        <div className="d-flex align-items-center">
                            {/* LanguageDropdown */}
                            <LanguageDropdown />

                            {/* FullScreenDropdown */}
                            <FullScreenDropdown />

                            {/* Dark/Light Mode set */}
                            <LightDark
                                layoutMode={layoutModeType}
                                onChangeLayoutMode={onChangeLayoutMode}
                            />
                            {/* ProfileDropdown */}
                            <ProfileDropdown />
                        </div>
                    </div>
                </div>
            </header>
        </React.Fragment>
    );
};

export default Header;
