import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  Modal,
  ModalHeader,
  Spinner,
  Form,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import MetaTags from "react-meta-tags";
//i18n
import { withTranslation } from "react-i18next";

import { CompanyTypeTable } from "./GridTablesData";

import CustomSelect from "../../Components/Common/CustomSelect";
import { isActiveOptions } from "../../data/selectOptions";

import FormGroupText from "../../Components/Common/FormGroupText";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  useAddCompanyType,
  useDeleteCompanyType,
  useFindCompanyType,
  useUpdateCompanyType,
} from "../../hooks/useCompanyType";
import DeleteModal from "../../Components/Common/DeleteModal";

const CompanyType = (props) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const { data: companyTypeData } = useFindCompanyType(selectedUser);
  const {
    mutate: createCompanyType,
    isSuccess: isSuccessCompanyType,
    isLoading: isAddingCompanyType,
  } = useAddCompanyType();
  const {
    mutate: updateCompanyType,
    isSuccess: isSuccessEditCompanyType,
    isLoading: isEditCompanyType,
  } = useUpdateCompanyType();

  const {
    mutate: deleteCompanyType,
    isSuccess: isSuccessDeleteCompanyType,
    isLoading: isDeletingCompanyType,
  } = useDeleteCompanyType();

  const [modal_standard, setmodal_standard] = useState(false);

  function tog_standard() {
    setmodal_standard(!modal_standard);
  }

  const [delete_modal, setdelete_modal] = useState(false);

  function tog_delete() {
    setdelete_modal(!delete_modal);
  }

  const handleEditCompanyType = (id, data) => {
    updateCompanyType({ id, data });
    setSelectedUser(null);
  };

  const handleOpenModal = (id) => {
    setSelectedUser(id);
    tog_standard();
  };

  const handleDeleteModal = (id) => {
    setSelectedUser(id);
    tog_delete();
  };

  const deleteModal = () => {
    deleteCompanyType({ id: selectedUser });
  };

  useEffect(() => {
    if (isSuccessCompanyType && !isAddingCompanyType) {
      setmodal_standard(!modal_standard);
      validationCompanyType.resetForm();
    }
    if (isSuccessCompanyType && !isAddingCompanyType) {
      toast(props.t("Successfully added CompanyType!"), {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: false,
        className: "bg-success text-white",
      });
    }
  }, [isSuccessCompanyType, isAddingCompanyType]);

  useEffect(() => {
    if (isSuccessEditCompanyType && !isEditCompanyType) {
      setmodal_standard(!modal_standard);
      validationCompanyType.resetForm();
    }
    if (isSuccessEditCompanyType && !isEditCompanyType) {
      toast(props.t("Successfully edited CompanyType!"), {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: false,
        className: "bg-warning text-white",
      });
    }
  }, [isSuccessEditCompanyType, isEditCompanyType]);

  useEffect(() => {
    if (isSuccessDeleteCompanyType && !isDeletingCompanyType) {
      tog_delete();
      setSelectedUser(null);
    }
    if (isSuccessDeleteCompanyType && !isDeletingCompanyType) {
      toast(props.t("Successfully deleted Company Type!"), {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: false,
        className: "bg-danger text-white",
      });
    }
  }, [isSuccessDeleteCompanyType, isDeletingCompanyType]);

  const validationCompanyType = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      label: companyTypeData?.label || "",
      isactive: companyTypeData?.is_active || "aktiv",
    },

    validationSchema: Yup.object({
      label: Yup.string().required(props.t("Name_is_required")),
      isactive: Yup.string().oneOf(
        isActiveOptions.map((item) => item.value),
        props.t("You must select at least one of the options") +
          isActiveOptions.map((item) => item.label)
      ),
    }),
    onSubmit: (values, { resetForm }) => {
      const data = {
        label: values.label,
        is_active: values.isactive,
      };
      if (selectedUser) {
        handleEditCompanyType(selectedUser, data);
      } else {
        createCompanyType(data);
      }
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <MetaTags>
            <title>
              {props.t("Company_Type")} | {props.t("MyHealth Brend")}
            </title>
          </MetaTags>
          <BreadCrumb
            title={props.t("Company_Type")}
            pageTitle={props.t("Company_Type")}
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="align-items-center d-flex border-bottom border-3 border-primary">
                  <h4 className="card-title mb-0 flex-grow-1">
                    {props.t("Company_Type")}
                  </h4>
                  <div className="flex-shrink-0">
                    <div className="form-check form-switch form-switch-right form-switch-md">
                      <Button
                        className="btn btn-danger"
                        onClick={() => tog_standard()}
                      >
                        <i className="ri-add-fill label-icon align-middle me-2"></i>
                        {props.t("Add_Company_Type")}
                      </Button>
                    </div>
                  </div>
                </CardHeader>

                <CardBody>
                  <div id="table-gridjs">
                    <CompanyTypeTable
                      handleOpenModal={handleOpenModal}
                      handleDeleteModal={handleDeleteModal}
                      deleteText={props.t("Delete")}
                      actionDoots={props.t("Action doots")}
                      edit={props.t("Edit")}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Company Type Modal */}
      <Modal
        id="myModal"
        onClosed={() => {
          setSelectedUser(null);
          validationCompanyType.setTouched({}, false);
        }}
        isOpen={modal_standard}
        toggle={() => {
          ((!isAddingCompanyType && selectedUser === null) ||
            (!isEditCompanyType && selectedUser !== null)) &&
            tog_standard();
        }}
      >
        <ModalHeader
          className="p-3 bg-primary"
          toggle={() => {
            ((!isAddingCompanyType && selectedUser === null) ||
              (!isEditCompanyType && selectedUser !== null)) &&
              tog_standard();
          }}
        >
          <span className="text-white">{props.t("Company_Type")}</span>
        </ModalHeader>
        <Form
          className="needs-validation"
          onSubmit={(e) => {
            e.preventDefault();
            validationCompanyType.handleSubmit();
            return false;
          }}
        >
          <div className="modal-body">
            {selectedUser !== null && companyTypeData === undefined && (
              <Row>
                <Col lg="12">
                  <Spinner />
                </Col>
              </Row>
            )}

            <Row>
              <Col lg="12">
                <FormGroupText
                  disabled={
                    isAddingCompanyType ||
                    isEditCompanyType ||
                    (selectedUser !== null && companyTypeData === undefined)
                  }
                  idInput="label"
                  label={props.t("Name")}
                  isRequired={true}
                  attributeName="label"
                  placeholder=""
                  validation={validationCompanyType}
                  type="text"
                />
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <CustomSelect
                  isDisabled={
                    isAddingCompanyType ||
                    isEditCompanyType ||
                    (selectedUser !== null && companyTypeData === undefined)
                  }
                  label={props.t("Status")}
                  onChange={(value) =>
                    validationCompanyType.setFieldValue("isactive", value.value)
                  }
                  selectOptions={isActiveOptions}
                  selectValues={validationCompanyType.values.isactive}
                />
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            {(!isAddingCompanyType && selectedUser === null) ||
            (!isEditCompanyType && selectedUser !== null) ? (
              <>
                <Button
                  color="light"
                  onClick={() => {
                    tog_standard();
                    setSelectedUser(null);
                  }}
                >
                  {props.t("Close")}
                </Button>
                <Button color="primary" type="submit">
                  {props.t("Submit")}
                </Button>
              </>
            ) : (
              <Button disabled color="primary" className="btn-load">
                <span className="d-flex align-items-center">
                  <span className="flex-grow-1 me-2">{props.t("Loading")}</span>
                  <Spinner size="sm" className="flex-shrink-0" role="status" />
                </span>
              </Button>
            )}
          </div>
        </Form>
      </Modal>
      <DeleteModal
        show={delete_modal}
        onCloseClick={tog_delete}
        onDeleteClick={deleteModal}
        isDeleteModal={isDeletingCompanyType}
      />
    </React.Fragment>
  );
};

export default withTranslation()(CompanyType);
