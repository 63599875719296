import React from "react";
import { Col, Input, FormGroup } from "reactstrap";

const WorkingTime = ({ validation, objName, ...props }) => {
  const objHour =
    `${validation?.values[objName].hour}`?.length === 1
      ? `0${validation?.values[objName].hour}`
      : `${validation?.values[objName].hour}`;
  const objMinute =
    `${validation?.values[objName].minute}`?.length === 1
      ? `0${validation?.values[objName].minute}`
      : `${validation?.values[objName].minute}`;
  return (
    <>
      <FormGroup>
        <Col lg={12}>
          <div className="input-group">
            <Input
              name={objName}
              type="time"
              value={objHour + ":" + objMinute}
              onChange={(e) => {
                let hour = e.target.value.split(":")[0];
                let minute = e.target.value.split(":")[1];
                validation.setFieldValue(objName, {
                  hour,
                  minute,
                });
              }}
              onBlur={validation?.handleBlur}
            />
          </div>
        </Col>
      </FormGroup>
    </>
  );
};

export default WorkingTime;
