import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  createEmployee,
  findEmployee,
  getAllEmployees,
  getEveryEmployee,
  updateEmployee,
} from "../services/employees";
import { toast } from "react-toastify";
export function useEveryEmployee(id) {
  return useQuery(["employees"], () => getEveryEmployee(id));
}

export function useCreateEmployee() {
  const queryClient = useQueryClient();
  return useMutation(createEmployee, {
    onSuccess: () => {
      queryClient.invalidateQueries(["employees"]);
    },
    onError: (error) => {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message, {
          position: "top-right",
          hideProgressBar: true,
          closeOnClick: false,
          className: "bg-danger text-white",
        });
      }
    },
  });
}

export function useFindEmployee(id) {
  return useQuery(["employees", id], () => findEmployee(id), {
    enabled: !!id,
  });
}

export function useUpdateEmployee(id) {
  const queryClient = useQueryClient();
  return useMutation(({ id, data }) => updateEmployee(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(["employees"]);
    },
  });
}
