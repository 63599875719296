import api from "./axios";

export const createArticle = (data) => {
  return api.post("/article", data, {
    headers: {
        'Content-Type': 'multipart/form-data'
      }
  });
};

export const findArticle = (id) => {
  return api(`/article/${id}`);
};

export const getAllArticles = () => {
  return api("/articles");
};

export const updateArticle = (id, data) => {
  return api.put(`/article/${id}`, data);
};


export const deleteArticle = (id) => {
  return api.delete(`/article/${id}`);
};
