import React, { useContext, useState, useEffect } from "react";
import {Link} from 'react-router-dom'
import { Grid, _ } from "gridjs-react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Spinner,
  Badge,
} from "reactstrap";
import deDE from "../../locales/deGrid";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../store/AuthContext";

import { useAllCompanies } from "../../hooks/useCompany";
import { useTranslation } from "react-i18next";
// Base Example
const CompaniesTable = ({ actionDoots, edit, deleteText, handleDeleteModal }) => {
  const language = localStorage.getItem("I18N_LANGUAGE");
  // const { user } = useContext(AuthContext);
  // const [isAdmin, setIsAdmin] = useState(false);
  // const [params, setParams] = useState("");

  // useEffect(() => {
  //   user?.roles.forEach((role) => {
  //     role?.name?.includes("admin") && setIsAdmin(true);
  //   });
  // }, [user]);

  const history = useHistory();

  const { data: companyData, isLoading, isFetching } = useAllCompanies();
  
  const data = companyData;



  const [modal_standard, setmodal_standard] = useState(false);
  const tog_standard = () => {
    setmodal_standard(!modal_standard);
  };
  const { t } = useTranslation();
  return (
    <React.Fragment>
      {isLoading || isFetching ? (
        <div className="d-flex justify-content-center">
          <Spinner />
        </div>
      ) : (
        data && (
          <Grid
            data={data}
            columns={[
              {
                name: "ID",
                data: (cell) => cell,
                width: "65px",
                formatter: (cell) =>
                  _(
                    <span className="fw-semibold">
                      {data?.findIndex((d) => d._id === cell._id) + 1}
                    </span>
                  ),
              },
              {
                name: t("Name"),
                data: (cell) =>
                  data?.find(
                    (ser) => ser.company_name === cell.company_name
                  ).company_name,
                formatter: (cell) => _(<span>{cell}</span>),
              },
              {
                name: "Allow Appointments",
                data: (cell) => cell.marketing,
                formatter: (cell) =>
                  _(
                    cell === "1" ? (
                      <Badge
                        className="fw-semibold text-uppercase"
                        color="success"
                      >
                        {t("true")}
                      </Badge>
                    ) : (
                      <Badge
                        className="fw-semibold text-uppercase"
                        color="danger"
                      >
                        {t("false")}
                      </Badge>
                    )
                  ),
              },
              {
                name: t("Super Company"),
                data: (cell) => cell?.["is_super_company"],
                formatter: (cell) => _(<span>{cell ? "Yes" : "No"}</span>),
              },  
              {
                name: "Company URL",
                data: (cell) => data?.find((ser) => ser._id === cell._id),
                formatter: (cell) => _(<a href={`https://stylingcorner.ch/company-details/${cell._id}`} style={{color: "blue"}}>{`https://stylingcorner.ch/company-details/${cell._id}`}</a>),
              },            
              {
                name: t("Status"),
                data: (cell) =>
                  data?.find((ser) => ser.is_active === cell.is_active)
                    .is_active,
                formatter: (cell) =>
                  _(
                    cell === "aktiv" ? (
                      <Badge
                        className="fw-semibold text-uppercase"
                        color="success"
                      >
                        {cell}
                      </Badge>
                    ) : (
                      <Badge
                        className="fw-semibold text-uppercase"
                        color="danger"
                      >
                        {cell}
                      </Badge>
                    )
                  ),
              },
              {
                name: actionDoots,
                width: "120px",
                data: (cell) =>
                  data?.find((ser) => ser._id === cell._id),
                formatter: (cell) =>
                  _(
                    <UncontrolledDropdown>
                      <DropdownToggle
                        className="btn-primary btn-sm shadow-none"
                        tag="button"
                      >
                        <i className="ri-more-fill" />
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem
                          onClick={() =>
                            history.push(`/edit-company/${cell._id}`)
                          }
                        >
                          <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                          {edit}
                        </DropdownItem>
                        <DropdownItem
                        onClick={() => handleDeleteModal(cell?._id)}
                      >
                        <i className="ri-refresh-line align-bottom me-2 text-muted"></i>{" "}
                        {deleteText}
                      </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  ),
              },
            ]}
            search={true}
            sort={true}
            pagination={{ enabled: true, limit: 10 }}
            language={language === "gr" && deDE}
          />
        )
      )}
    </React.Fragment>
  );
};

export { CompaniesTable };
