import React from "react";
import { useFormik, yupToFormErrors } from "formik";
import { Col, Row, Button, Form, Input, FormGroup } from "reactstrap";
import CustomSelect from "./CustomSelect";
import { dayOfTheWeek, typeDayWork } from "../../data/selectOptions";
import WorkingTime from "./WorkingTime";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { BsPencil as Pencil, BsTrash as Trash } from "react-icons/bs";
import { useParams } from "react-router-dom";

const WorkingTimeRow = ({
  workingTime,
  handleUpdateSchedule,
  handleAddDay,
  ServiceId,
  handleDeleteDay,
  day,
}) => {
  const currentDayScedule = workingTime?.find((d) => d.day === Number(day));
  const params = useParams();
  const { t } = useTranslation();
  const validationWorkingTime = useFormik({
    enableReinitialize: true,
    initialValues: {
      start: {
        hour: currentDayScedule?.start?.hour || 0,
        minute: currentDayScedule?.start?.minute || 0,
      },
      end: {
        hour: currentDayScedule?.end?.hour || 0,
        minute: currentDayScedule?.end?.minute || 0,
      },
      pause_start: {
        hour: currentDayScedule?.pause_start?.hour || 0,
        minute: currentDayScedule?.pause_start?.minute || 0,
      },
      pause_end: {
        hour: currentDayScedule?.pause_end?.hour || 0,
        minute: currentDayScedule?.pause_end?.minute || 0,
      },
      type: currentDayScedule?.type || "Closed",
    },
    validationSchema: yup.object({}),
    onSubmit: (values) => {
      const data = {
        start: {
          hour: Number(values.start.hour),
          minute: Number(values.start.minute),
        },
        end: {
          hour: Number(values.end.hour),
          minute: Number(values.end.minute),
        },
        pause_start: {
          hour: Number(values.pause_start.hour),
          minute: Number(values.pause_start.minute),
        },
        pause_end: {
          hour: Number(values.pause_end.hour),
          minute: Number(values.pause_end.minute),
        },
        day: day,
        type: values.type || "Closed",
        id_service: ServiceId || null,
        id_company: params.id,
      };
      if (currentDayScedule) {
        handleUpdateSchedule(currentDayScedule._id, data);
      } else {
        handleAddDay(data);
      }
    },
  });

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        validationWorkingTime.handleSubmit();
        return false;
      }}
    >
      <Row>
        <Col md={2}>
          <WorkingTime validation={validationWorkingTime} objName="start" />
        </Col>
        <Col md={2}>
          <WorkingTime validation={validationWorkingTime} objName="end" />
        </Col>
        <Col md={2}>
          <WorkingTime
            validation={validationWorkingTime}
            objName="pause_start"
          />
        </Col>
        <Col md={2}>
          <WorkingTime validation={validationWorkingTime} objName="pause_end" />
        </Col>
        <Col md={2}>
          <CustomSelect
            onChange={(option) =>
              validationWorkingTime.setFieldValue("type", option.value)
            }
            selectOptions={typeDayWork}
            selectValues={validationWorkingTime.values.type}
          />
        </Col>
        <Col md={2}>
          <Button
            type="submit"
            color={currentDayScedule ? "success" : "primary"}
          >
            {currentDayScedule ? <Pencil /> : t("Add")}
          </Button>
          {currentDayScedule && (
            <Button
              style={{ marginLeft: "3px" }}
              color="danger"
              type="submit"
              onClick={() => handleDeleteDay(currentDayScedule?._id)}
            >
              <Trash />
            </Button>
          )}
        </Col>
      </Row>
    </Form>
  );
};

export default WorkingTimeRow;
