import React, { useContext, useState, useEffect } from "react";
import { Grid, _ } from "gridjs-react";
import {
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import deDE from "../../locales/deGrid";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../store/AuthContext";
import { useTranslation } from "react-i18next";
import { useEveryUser } from "../../hooks/useUsers";
import { DateTime } from 'luxon'
import { useAllCompanies } from "../../hooks/useCompany";

// UserTable Example
const UserTable = ({
  handleOpenModal,
  firstname,
  lastname,
  phone,
  actionDoots,
  edit,
}) => {
  const language = localStorage.getItem("I18N_LANGUAGE");
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const [isAdmin, setIsAdmin] = useState(false);
  const { data: employees } = useEveryUser();
  const { data: companyData, isLoading, isFetching } = useAllCompanies();

  useEffect(() => {
    user?.roles.forEach((role) => {
      role?.name?.includes("admin") && setIsAdmin(true);
    });
  }, [user]);

  const history = useHistory();
  const data = employees;
  const [modal_standard, setmodal_standard] = useState(false);
  const tog_standard = () => {
    setmodal_standard(!modal_standard);
  };
  return (
    <React.Fragment>
      {data && (
        <Grid
          data={data}
          columns={[
            {
              name: "ID",
              data: (cell) => cell,
              width: "65px",
              formatter: (cell) =>
                _(
                  <span className="fw-semibold">
                    {data?.findIndex((d) => d._id === cell._id) + 1}
                  </span>
                ),
            },
            {
              name: firstname,
              data: (cell) =>
                data?.find((ser) => ser?.fname === cell?.fname)?.fname,
              formatter: (cell) =>
                _(
                  <span
                    className={
                      !cell &&
                      "fw-semibold badge badge-soft-danger text-uppercase"
                    }
                  >
                    {cell ? cell : "K.A"}
                  </span>
                ),
            },
            {
              name: lastname,
              data: (cell) =>
                data?.find((ser) => ser?.lname === cell?.lname)?.lname,
              formatter: (cell) =>
                _(
                  <span
                    className={
                      !cell &&
                      "fw-semibold badge badge-soft-danger text-uppercase"
                    }
                  >
                    {cell ? cell : "K.A"}
                  </span>
                ),
            },
            {
              name: "Company Name",
              data: (cell) =>
                companyData?.find((ser) => ser?._id === cell?.companyId)?.company_name,
              formatter: (cell) =>
                _(
                  <span
                    className={
                      !cell &&
                      "fw-semibold badge badge-soft-danger text-uppercase"
                    }
                  >
                    {cell ? cell : "K.A"}
                  </span>
                ),
            },
            {
              name: phone,
              data: (cell) =>
                data?.find((ser) => ser?.phone === cell?.phone)?.phone,
              formatter: (cell) =>
                _(
                  <span
                    className={
                      !cell &&
                      "fw-semibold badge badge-soft-danger text-uppercase"
                    }
                  >
                    {cell ? cell : "K.A"}
                  </span>
                ),
            },
            {
              name: t("Email"),
              data: (cell) =>
                data?.find((ser) => ser?.email === cell?.email)?.email,
              formatter: (cell) =>
                _(
                  <span
                    className={
                      !cell &&
                      "fw-semibold badge badge-soft-danger text-uppercase"
                    }
                  >
                    {cell ? cell : "K.A"}
                  </span>
                ),
            },
            {
              name: t("Expiry Date"),
              data: (cell) => DateTime.fromMillis(data?.find((ser) => ser?.expiryDate === cell?.expiryDate)?.expiryDate).toFormat('dd.LL.yyyy'),
            },
            {
              name: t("Status"),
              data: (cell) =>
                data?.find((ser) => ser.is_active === cell.is_active).is_active,
              formatter: (cell) =>
                _(
                  cell === "aktiv" ? (
                    <Badge
                      className="fw-semibold text-uppercase"
                      color="success"
                    >
                      {cell}
                    </Badge>
                  ) : (
                    <Badge
                      className="fw-semibold text-uppercase"
                      color="danger"
                    >
                      {cell}
                    </Badge>
                  )
                ),
            },
            {
              name: actionDoots,
              width: "120px",
              data: (cell) => data?.find((ser) => ser._id === cell._id),
              formatter: (cell) =>
                _(
                  <UncontrolledDropdown>
                    <DropdownToggle
                      className="btn-primary btn-sm shadow-none"
                      tag="button"
                    >
                      <i className="ri-more-fill" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem onClick={() => handleOpenModal(cell?._id)}>
                        <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                        {edit}
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                ),
            },
          ]}
          search={true}
          sort={true}
          pagination={{ enabled: true, limit: 10 }}
          language={language === "gr" && deDE}
        />
      )}
    </React.Fragment>
  );
};

export { UserTable };
