import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { withTranslation } from "react-i18next";
import { Alert, Button, Col, Form, Input, Label, Row } from "reactstrap";

import { AuthContext } from "../../../store/AuthContext";
import FormGroupText from "../../Common/FormGroupText";
import CustomSelect from "../../Common/CustomSelect";
import {
  countryOptions,
  isActiveOptions,
  isCompanyRegisteredOptions,
  isSuperCompanyOptions,
} from "../../../data/selectOptions";
import ImageInput from "../../Common/ImageInput";
import useCompanyTypes from "../../../hooks/useCompanyType";
import { Editor } from "../../Editor/editor";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { toast } from "react-toastify";

const CompanyDetailsForm = ({
  validation,
  toggleTab,
  company,
  handleCreateCompany,
  setAllowEdit,
  isSuperCompany,
  setCoverImages,
  setLogo,
  contentValue,
  setContentValue,
  agbValue,
  setAgbValue,
  error,
  ...props
}) => {
  const { data: companyTypes } = useCompanyTypes();
  const [phoneValue, setPhoneValue] = useState();
  const [companyCategories, setCompanyCategories] = useState([]);

  useEffect(() => {
    companyTypes?.map(
      (companyType) =>
        companyType.is_active === "aktiv" &&
        setCompanyCategories((companyCategories) => [
          ...companyCategories,
          {
            label: companyType.label,
            value: companyType.label,
          },
        ])
    );
  }, [companyTypes]);

  const countryOptions = [
    { value: "ch", label: props.t("Switzerland") },
    { value: "de", label: props.t("Deutschland") },
    { value: "at", label: props.t("Österreich") },
  ];

  useEffect(() => {
    if (phoneValue) {
      validation?.setFieldValue("phone", phoneValue);
    } else {
      validation?.setFieldValue("phone", "");
    }
  }, [phoneValue]);

  return (
    // <Form
    //   className="needs-validation"
    //   onSubmit={(e) => {
    //     e.preventDefault();
    //     validation.handleSubmit();
    //     return false;
    //   }}
    // >
    <>
      <Row>
        <Col log={12}>
          <Alert color="info" className="shadow">
            {props.t("Company Info Alert")}
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <ImageInput
            label={props.t("Logo")}
            isRequired={false}
            name="logo"
            multiple={false}
            accept="image/*"
            onChange={(e) => {
              setLogo(e.target.files);
            }}
          />
        </Col>
        <Col md={6}>
          <ImageInput
            label={props.t("Cover image")}
            isRequired={false}
            accept="image/*"
            name="slider"
            onChange={(e) => {
              setCoverImages(e.target.files);
            }}
            multiple={false}
          />
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <CustomSelect
            label={props.t("Company category")}
            isRequired={true}
            onChange={(option) =>
              validation.setFieldValue(
                "company_type",
                option.map((item) => item.value)
              )
            }
            selectOptions={companyCategories}
            selectValues={validation.values.company_type}
            isMulti={true}
            styles={
              validation.touched.department &&
              validation.errors.department && {
                container: (base) => ({
                  ...base,
                  backgroundColor: "#e74c3c",
                  padding: "0.8px",
                  borderRadius: "5px",
                }),
              }
            }
          >
            {validation.touched.department && validation.errors.department && (
              <p
                style={{
                  color: "#f06548",
                  marginTop: "0.25rem",
                  fontSize: "0.875em",
                }}
              >
                {validation.errors.department}
              </p>
            )}
          </CustomSelect>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <FormGroupText
            idInput="company_name"
            label={props.t("Company Name")}
            isRequired={true}
            attributeName="company_name"
            placeholder=""
            validation={validation}
            type="text"
          />
        </Col>
      </Row>
      <Row>
        <Col lg={validation.values["isCompanyRegistered"] ? "6" : "12"}>
          <CustomSelect
            label={props.t("Is Company Registered")}
            isRequired={true}
            onChange={(value) =>
              validation.setFieldValue("isCompanyRegistered", value.value)
            }
            selectOptions={isCompanyRegisteredOptions}
            selectValues={validation.values["isCompanyRegistered"]}
            styles={
              validation.touched["isCompanyRegistered"] &&
              validation.errors["isCompanyRegistered"] && {
                container: (base) => ({
                  ...base,
                  backgroundColor: "#e74c3c",
                  padding: "0.8px",
                  borderRadius: "5px",
                }),
              }
            }
          >
            {validation.touched["isCompanyRegistered"] &&
              validation.errors["isCompanyRegistered"] && (
                <p
                  style={{
                    color: "#f06548",
                    marginTop: "0.25rem",
                    fontSize: "0.875em",
                  }}
                >
                  {validation.errors["isCompanyRegistered"]}
                </p>
              )}
          </CustomSelect>
        </Col>
        {validation.values["isCompanyRegistered"] && (
          <Col lg="6">
            <FormGroupText
              idInput="uID"
              label={props.t("uID")}
              type="text"
              isRequired={true}
              attributeName="uID"
              placeholder=""
              validation={validation}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col lg={validation.values["is_super_company"] ? "6" : "12"}>
          <CustomSelect
            label={props.t("Is Super Company")}
            isRequired={true}
            onChange={(value) =>
              validation.setFieldValue("is_super_company", value.value)
            }
            selectOptions={isSuperCompanyOptions}
            selectValues={validation.values["is_super_company"]}
            styles={
              validation.touched["is_super_company"] &&
              validation.errors["is_super_company"] && {
                container: (base) => ({
                  ...base,
                  backgroundColor: "#e74c3c",
                  padding: "0.8px",
                  borderRadius: "5px",
                }),
              }
            }
          >
            {validation.touched["is_super_company"] &&
              validation.errors["is_super_company"] && (
                <p
                  style={{
                    color: "#f06548",
                    marginTop: "0.25rem",
                    fontSize: "0.875em",
                  }}
                >
                  {validation.errors["is_super_company"]}
                </p>
              )}
          </CustomSelect>
        </Col>
        {validation.values["is_super_company"] && (
          <Col lg="6">
            <FormGroupText
              idInput="subCompaniesAllowed"
              label={props.t("Number of sub companies allowed")}
              type="number"
              isRequired={true}
              attributeName="subCompaniesAllowed"
              placeholder=""
              validation={validation}
            />
          </Col>
        )}
      </Row>
      <Row>
        <label className="form-label">
          Allow Appointments <span className="text-danger">*</span>
        </label>
        <div>
          <div className="form-check form-check-inline">
            <Input
              defaultChecked={validation?.values?.marketing === "1" && "true"}
              className="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="1"
              onChange={(e) =>
                validation.setFieldValue("marketing", e.target.value)
              }
              value="1"
            />
            <label className="form-check-label" htmlFor="1">
              {props.t("true")}
            </label>
          </div>
          <div className="form-check form-check-inline">
            <Input
              defaultChecked={validation?.values?.marketing === "0" && "true"}
              className="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="0"
              onChange={(e) =>
                validation.setFieldValue("marketing", e.target.value)
              }
              value="0"
            />
            <label className="form-check-label" htmlFor="0">
              {props.t("false")}
            </label>
          </div>
        </div>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col lg="12">
          <Label htmlFor="about_company">
            {props.t("About Company")} <span className="text-danger">*</span>
          </Label>
          <Editor
            name="about_company"
            value={contentValue}
            setValue={setContentValue}
          />
          {error && (
            <p
              style={{
                color: "#f06548",
                marginTop: "0.25rem",
                fontSize: "0.875em",
              }}
            >
              {error}
            </p>
          )}
          {/* <FormGroupText
            idInput="about_company"
            label={props.t("About Company")}
            isRequired={true}
            attributeName="about_company"
            validation={validation}
            type="textarea"
            rows={3}
          /> */}
        </Col>
      </Row>

      <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
        <Col lg="12">
          <Label htmlFor="agb">
            {props.t("Allgemeine Geschäftsbedingungen")}{" "}
            <span className="text-danger">*</span>
          </Label>
          <Editor name="agb" value={agbValue} setValue={setAgbValue} />
          {error && (
            <p
              style={{
                color: "#f06548",
                marginTop: "0.25rem",
                fontSize: "0.875em",
              }}
            >
              {error}
            </p>
          )}
          {/* <FormGroupText
            idInput="about_company"
            label={props.t("About Company")}
            isRequired={true}
            attributeName="about_company"
            validation={validation}
            type="textarea"
            rows={3}
          /> */}
        </Col>
      </Row>

      <Row>
        <Col lg="12">
          <div style={{ marginBottom: "1rem" }}>
            <Label htmlFor="phone">
              {"Phone"} {<span className="text-danger">*</span>}
            </Label>
            <PhoneInput
              style={{ border: "1px solid #ced4da" }}
              numberInputProps={{
                className: "phone-input",
              }}
              name="phone"
              id="phone"
              placeholder="Enter phone number"
              defaultCountry="CH"
              value={phoneValue}
              onChange={setPhoneValue}
            />
            {validation.touched.phone && validation.errors.phone && (
              <p
                style={{
                  color: "#f06548",
                  marginTop: "0.25rem",
                  fontSize: "0.875em",
                }}
              >
                {validation.errors.phone}
              </p>
            )}
          </div>
        </Col>
        <Col lg="12">
          <FormGroupText
            idInput="fax"
            label={props.t("Phone Two")}
            attributeName="fax"
            placeholder=""
            validation={validation}
            type="text"
          />
        </Col>
      </Row>

      <Row>
        <Col lg="12">
          <FormGroupText
            idInput="email"
            label={props.t("Email One")}
            isRequired={true}
            attributeName="email"
            placeholder=""
            validation={validation}
            type="email"
          />
        </Col>
        <Col lg="12">
          <FormGroupText
            idInput="website"
            label={props.t("Website")}
            attributeName="website"
            placeholder=""
            validation={validation}
            type="text"
          />
        </Col>
      </Row>

      <Row>
        <Col lg="12">
          <FormGroupText
            idInput="konkordac"
            label={props.t("Konkordac")}
            attributeName="konkordac"
            placeholder=""
            validation={validation}
            type="text"
          />
        </Col>
        <Col lg="12">
          <FormGroupText
            idInput="gln_number"
            label={props.t("GlnNumber")}
            attributeName="gln_number"
            placeholder=""
            validation={validation}
            type="text"
          />
        </Col>
      </Row>

      <Row>
        <Col lg="12">
          <FormGroupText
            idInput="address"
            label={props.t("Address")}
            isRequired={true}
            attributeName="address"
            placeholder=""
            validation={validation}
            type="text"
          />
        </Col>
        <Col lg="12">
          <FormGroupText
            idInput="address_no"
            label={props.t("AddressNo")}
            attributeName="address_no"
            placeholder=""
            validation={validation}
            type="text"
          />
        </Col>
      </Row>

      <Row>
        <Col md="4">
          <FormGroupText
            idInput="zip"
            label={props.t("Zip")}
            isRequired={true}
            attributeName="zip"
            placeholder=""
            validation={validation}
            type="text"
          />
        </Col>
        <Col md="4">
          <FormGroupText
            idInput="city"
            label={props.t("City")}
            isRequired={true}
            attributeName="city"
            placeholder=""
            validation={validation}
            type="text"
          />
        </Col>
        <Col md="4">
          <CustomSelect
            label={props.t("Country")}
            onChange={(value) =>
              validation.setFieldValue("country", value.value)
            }
            selectOptions={countryOptions}
            selectValues={validation.values.country}
          />
        </Col>
      </Row>

      <Row>
        <Col lg={12}>
          <CustomSelect
            label={props.t("Status")}
            onChange={(value) =>
              validation.setFieldValue("is_active", value.value)
            }
            selectOptions={isActiveOptions}
            selectValues={validation.values.is_active}
          />
        </Col>
      </Row>

      <Row className="text-end mt-3">
        <Col lg={12}>
          <Button
            onClick={() =>
              Object.keys(validation.errors).length === 2 && toggleTab("3")
            }
            className="btn btn-primary"
            type="submit"
          >
            {props.t("Next")}
          </Button>
        </Col>
      </Row>
    </>
    // </Form>
  );
};

export default withTranslation()(CompanyDetailsForm);
