import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import AuthContextProvider from "./store/AuthContext";

import { configureStore } from "./store";
import { QueryClient, QueryClientProvider } from "react-query";
import ReserveAppointmentContextProvider from "./store/ReserveAppointmentContext";
const queryClient = new QueryClient();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <AuthContextProvider>
      <ReserveAppointmentContextProvider>
        <Provider store={configureStore({})}>
          <React.Fragment>
            <BrowserRouter basename={process.env.PUBLIC_URL}>
              <App />
            </BrowserRouter>
          </React.Fragment>
        </Provider>
      </ReserveAppointmentContextProvider>
    </AuthContextProvider>
  </QueryClientProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
