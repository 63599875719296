import api from "./axios";

export const loginService = async (data) => {
  return api.post("/auth/signin", data);
};

export const verifiLogin = async (data) => {
  return api.post("/auth/verify", data);
};

export const sendForgotPassword = async (data) => {
  return api.post("/auth/sendpassword", data);
};

export const verifyForgotPassword = async (data) => {
  return api.post("/auth/verifypassword", data);
};
