import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  Modal,
  ModalHeader,
  Spinner,
  Label,
  FormGroup,
  Form,
  Input,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import MetaTags from "react-meta-tags";
//i18n
import { withTranslation } from "react-i18next";

import FormGroupText from "../../Components/Common/FormGroupText";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useSettings } from "../../hooks/useSettings";

const Settings = (props) => {
  const { mutate, isSuccess, isLoading } = useSettings();

  useEffect(() => {
    if (isSuccess && !isLoading) {
      toast("Successfully updated Password!", {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: false,
        className: "bg-success text-white",
      });
    }
  }, [isSuccess, isLoading]);

  const validationSettings = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },

    validationSchema: Yup.object({
      old_password: Yup.string().required(props.t("Please enter old password")),
      new_password: Yup.string()
        .required(props.t("Please enter new password"))
        .matches(
          /^(?=.*[A-Z])(?=.*[0-9!@#\$%\^\&*\)\(+=._-]).{8,}$/,
          props.t("Password Validation")
        ),
      confirm_password: Yup.string()
        .required(props.t("Please enter confirm password"))
        .matches(
          /^(?=.*[A-Z])(?=.*[0-9!@#\$%\^\&*\)\(+=._-]).{8,}$/,
          props.t("Password Validation")
        )
        .oneOf([Yup.ref("new_password"), ""], props.t("Passwords must match")),
    }),

    onSubmit: (values) => {
      const payload = {
        old_password: values.old_password,
        new_password: values.new_password,
      };
      mutate(payload);
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <MetaTags>
            <title>
              {props.t("Settings")} | {props.t("MyHealth Brend")}
            </title>
          </MetaTags>
          <BreadCrumb
            title={props.t("Settings")}
            pageTitle={props.t("Settings")}
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="align-items-center d-flex border-bottom border-3 border-primary">
                  <h4 className="card-title mb-0 flex-grow-1">
                    {props.t("Settings")}
                  </h4>
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Form
        className="needs-validation"
        onSubmit={(e) => {
          e.preventDefault();
          validationSettings.handleSubmit();
        }}
      >
        <div
          style={{
            background: "white",
            marginLeft: "24px",
            marginRight: "24px",
            marginTop: "-70px",
          }}
          className="modal-body"
        >
          <Row>
            <Col lg="12">
              <FormGroupText
                disabled={isLoading}
                idInput="old_password"
                label={props.t("Old Password")}
                isRequired={true}
                attributeName="old_password"
                placeholder=""
                validation={validationSettings}
                type="password"
              />
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <FormGroupText
                disabled={isLoading}
                idInput="new_password"
                label={props.t("New Password")}
                isRequired={true}
                attributeName="new_password"
                placeholder=""
                validation={validationSettings}
                type="password"
              />
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <FormGroupText
                disabled={isLoading}
                idInput="confirm_password"
                label={props.t("Confirm Password")}
                isRequired={true}
                attributeName="confirm_password"
                placeholder=""
                validation={validationSettings}
                type="password"
              />
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          {!isLoading ? (
            <Button color="primary" type="submit">
              {props.t("Submit")}
            </Button>
          ) : (
            <Button disabled color="primary" className="btn-load">
              <span className="d-flex align-items-center">
                <span className="flex-grow-1 me-2">{props.t("Loading")}</span>
                <Spinner size="sm" className="flex-shrink-0" role="status" />
              </span>
            </Button>
          )}
        </div>
      </Form>
    </React.Fragment>
  );
};

export default withTranslation()(Settings);
