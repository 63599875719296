import api from "./axios";

export const createCompany = (data) => {
  return api.post("/company", data);
};

export const findCompany = (id) => {
  return api(`/company/${id}`);
};

export const getAllCompanies = () => {
  return api("/company/");
};

export const updateCompany = (id, data, isCoverImage) => {
  return api.put(`/company/${id}?slider=${isCoverImage}`, data);
};

export const updateCompanyStatus = (id, data) => {
  return api.post(`/updateStatus/${id}`, {status: data});
};

export const getCompanyLogo = async (id) => {
  return await api(`/api/logo/company`, {
    params: {
      company_name: id,
    },
  });
};

export const getCompanyImages = async (id) => {
  return await api(`/api/slider/company`, {
    params: {
      company_name: id,
    },
  });
};
export const deleteCompany = (id) => {
  return api.delete(`/company/${id}`);
};
