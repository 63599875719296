import PropTypes from "prop-types";
import React from "react";
import { Button, Modal, ModalBody, Spinner } from "reactstrap";
import lottie from "lottie-web";
import { defineLordIconElement } from "lord-icon-element";
import { useTranslation } from "react-i18next";
defineLordIconElement(lottie.loadAnimation);

const DeleteModal = ({ show, isUpdate = false, isActive, onDeleteClick, onCloseClick, isDeleteModal }) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center" style={{ fontSize: 100 }}>
        {!isUpdate &&  <lord-icon
            src="https://cdn.lordicon.com/exkbusmy.json"
            trigger="loop"
            colors="outline:#121331,primary:#646e78,secondary:#b4b4b4,tertiary:#ebe6ef"
            style={{ width: "125px", height: "125px" }}
          ></lord-icon>}
          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
            <h4>{isUpdate ? t("Update Status") : t("Delete Modal Title")}</h4>
            <p className="text-muted mx-4 mb-0">{isUpdate ? t("Update Modal Text") : t("Delete Modal Text")}</p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          {isDeleteModal ? (
            <Button disabled className="btn w-sm btn-danger btn-load">
              <span className="d-flex align-items-center">
                <span className="flex-grow-1 me-2">{isUpdate ? t("Updating") : t("Deleting")}</span>
                <Spinner size="sm" className="flex-shrink-0" role="status" />
              </span>
            </Button>
          ) : (
            <>
              <button
                type="button"
                className="btn w-sm btn-light"
                data-bs-dismiss="modal"
                onClick={onCloseClick}
              >
                {t("Close")}
              </button>
              <button
                type="button"
                className="btn w-sm btn-danger "
                id="delete-record"
                onClick={onDeleteClick}
              >
                {isUpdate ? t("Yes, Update It!") : t("Yes, Delete It!")}
              </button>
            </>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

DeleteModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
};

export default DeleteModal;
