import { useQuery, useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { updatePassword } from "../services/settings";

export function useSettings() {
  return useMutation((data) => updatePassword(data), {
    onError: (error) => {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message, {
          position: "top-right",
          hideProgressBar: true,
          closeOnClick: false,
          className: "bg-danger text-white",
        });
      }
    },
  });
}
