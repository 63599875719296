import api from "./axios";

export const getAllUsers = async () => {
  return api("/auth/users");
};

export const getEveryUser = async () => {
  return api("/auth/company-users");
};

export const createUser = (data) => {
  return api.post("/auth/signup", data);
};

export const findUser = (id) => {
  return api(`auth/user/${id}`);
};

export const updateUser = (id, data) => {
  return api.put(`auth/user/${id}`, data);
};
