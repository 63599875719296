import api from "./axios";

export const getAllEmployees = async () => {
  return api("/auth/employees");
};

export const getEveryEmployee = async (companyId) => {
  return api(companyId ? `/auth/company-employees?companyId=${companyId}` : 'auth/company-employees');
};

export const createEmployee = (data) => {
  return api.post("/auth/employee/signup", data);
};

export const findEmployee = (id) => {
  return api(`auth/user/${id}`);
};

export const updateEmployee = (id, data) => {
  return api.put(`auth/user/${id}`, data);
};
