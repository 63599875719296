import React from 'react';
import { Row, Col } from 'reactstrap';
import WorkingTimeRow from '../../Common/WorkingTimeRow';
import { format, setDay } from 'date-fns';

const WorkingScedule = ({
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    sunday,
    workingTime,
    handleUpdateSchedule,
    ServiceId,
    handleAddDay,
    handleDeleteDay,
}) => {
    const DAY_IN_WEEK = {
        0: sunday,
        1: monday,
        2: tuesday,
        3: wednesday,
        4: thursday,
        5: friday,
        6: saturday,
    };
    const days = Object.keys(DAY_IN_WEEK);
    return days.map(day => (
        <Row key={day} className="pt-3">
            <Col lg={1}>
                <b>{DAY_IN_WEEK[day]}</b>
            </Col>
            <Col lg={11}>
                <WorkingTimeRow
                    ServiceId={ServiceId}
                    workingTime={workingTime}
                    handleUpdateSchedule={handleUpdateSchedule}
                    handleAddDay={handleAddDay}
                    handleDeleteDay={handleDeleteDay}
                    day={day}
                />
            </Col>
        </Row>
    ));
};

export default WorkingScedule;
