import { useMutation, useQueryClient } from "react-query";
import { loginService } from "../services/authServices";

const useLoginUser = () => {
    const queryClient = useQueryClient();
  
    return useMutation(({ data }) => loginService(data), {
      onSuccess: () => {
        queryClient.invalidateQueries(["user"]);
      },
    });
  }

export default useLoginUser