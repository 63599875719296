import flagus from "../assets/images/flags/us.svg";
import flagswizz from "../assets/images/flags/switzerland.svg";

const languages = {
  gr: {
    label: "Deutsch",
    flag: flagswizz,
  },
  en: {
    label: "English",
    flag: flagus,
  }
}

export default languages
