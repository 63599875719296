import api from "./axios";

export const getAllWorkingTime = async (id) => {
  return api(`/company/scedule/${id}`);
};

export const getServiceWorkingTime = async (id) => {
  return await api(`/service/scedule/${id}`);
};

export const updateWorkingSchedule = (id, data) => {
  return api.put(`/scedule/${id}`, data);
};
export const createDay = (data) => {
  return api.post(`/scedule`, data);
};

export const deleteDay = (id) => {
  return api.delete(`/scedule/${id}`);
};
