import React, { useEffect, useState } from "react";

const Initals = ({ fname, lname, size }) => {
  return (
    <div
      style={{
        borderRadius: "50%",
        width: size === "lg" ? "6rem" : "50px",
        height: size === "lg" ? "6rem" : "50px",
        backgroundColor: "white",
        color: "grey",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: size === "lg" ? "2.5rem" : "1.35rem",
      }}
    >
      {`${fname?.charAt(0).toUpperCase()}${lname?.charAt(0).toUpperCase()}`}
    </div>
  );
};

export default Initals;
