import React from "react";
import classnames from "classnames";
import { Nav, NavItem, NavLink } from "reactstrap";
import { withTranslation } from "react-i18next";

const Navigation = ({ customActiveTab, toggleCustom, ...props }) => {
  const { t } = props;
  return (
    <Nav
      tabs
      className="nav nav-tabs nav-tabs-custom nav-success nav-justified mb-3"
    >
      <NavItem>
        <NavLink
          style={{ cursor: "pointer" }}
          className={classnames({
            active: customActiveTab === "1",
          })}
          onClick={() => {
            toggleCustom("1");
          }}
        >
          {t("Company Details")}
        </NavLink>
      </NavItem>
      {/* <NavItem>
                <NavLink
                    style={{ cursor: 'pointer' }}
                    className={classnames({
                        active: customActiveTab === '2',
                    })}
                    onClick={() => {
                        toggleCustom('2');
                    }}
                >
                    {t('Owner')}
                </NavLink>
            </NavItem> */}
      <NavItem>
        <NavLink
          style={{ cursor: "pointer" }}
          className={classnames({
            active: customActiveTab === "3",
          })}
        >
          {t("Google Maps")}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          style={{ cursor: "pointer" }}
          className={classnames({
            active: customActiveTab === "4",
          })}
        >
          {t("Social Media")}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          style={{ cursor: "pointer" }}
          className={classnames({
            active: customActiveTab === "6",
          })}
          onClick={() => {
            toggleCustom("6");
          }}
        >
          {t("Bank Account")}
        </NavLink>
      </NavItem>
    </Nav>
  );
};

export default withTranslation()(Navigation);
