import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../store/AuthContext";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
  Spinner,
} from "reactstrap";
import Slide from "../../Components/Common/UiCarousel/CarouselTypes/slide";
import { ReactComponent as PoweredByIcon } from "../../assets/images/powered-by.svg";

import { Link, useHistory, useLocation, withRouter } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
//i18n
import { withTranslation } from "react-i18next";

import logoLight from "../../assets/images/logo_dark.png";
import appointoGreen from "../../assets/images/appointo-green.svg"
//Import config
import LanguageDropdown from "../../Components/Common/LanguageDropdown";
import useLoginUser from "../../hooks/useLoginUser";
//import images

const Login = (props) => {
  const { push } = useHistory();
  const { state, search } = useLocation();
  const inputRef = useRef(null);
  const { login, user, failedLoging, isLoging } = useContext(AuthContext);
  const [isViewed, setIsViewed] = useState(false);
  const { email } = Object.fromEntries(new URLSearchParams(search));
  const {
    mutate: loginUser,
    isSuccess: successfullySignedUser,
    isLoading: isLogingUser,
    isFetching: isFetchingUser,
    error: loginErrors,
  } = useLoginUser();
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required(props.t("username_validation")),
      password: Yup.string().required(props.t("password_validation")),
    }),
    onSubmit: (values) => {
      loginUser({ data: values });
    },
  });

  const verifyValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      otp: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string()
        .min(6, props.t("otp_validation"))
        .max(6, props.t("otp_validation"))
        .required(props.t("username_validation")),
    }),
    onSubmit: (values) => {
      login({ email, otp: values.otp });
    },
  });

  useEffect(() => {
    if (successfullySignedUser) {
      push({ search: `?email=${validation.values.email}` });
    }
  }, [successfullySignedUser]);

  useEffect(() => {
    if (user) {
      if (state?.from) {
        push(state?.from);
      } else {
        push("/");
      }
    }
  }, [push, state?.from, user]);

  const toggleView = () => {
    setIsViewed(!isViewed);
    inputRef.current.focus();
  };
  const handleFocusChange = () => {
    isViewed && setIsViewed(false) && inputRef.current.blur();
  };

  return (
    <div className="auth-page-wrapper" style={{ height: "100vh" }}>
      <div className="auth-page-content">
        <MetaTags>
          <title>{props.t("MyHealth Brend")}</title>
        </MetaTags>
        <Container fluid>
          <Row style={{ height: "100vh" }}>
            <Col md={12} lg={6} xl={6} className="g-0">
              <Row style={{ backgroundColor: "white", paddingLeft: "10px" }}>
                <Col md={1}>
                  <LanguageDropdown />
                </Col>
                <Col md={11}>
                  <Card
                    className="m-0 w-100"
                    style={{
                      height: "100vh",
                      display: "flex",
                      boxShadow: "none",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CardBody>
                      <div className="text-center mt-2">
                        <div className="d-flex justify-content-center">
                          <img
                            src={appointoGreen}
                            className="card-logo card-logo-dark"
                            alt="logo dark"
                            width="400"
                          />
                        </div>
                        {/* <h2 className="text-dark">{props.t("Welcome back")} !</h2> */}
                        <p className="text-muted">
                          {props.t("Sign in to continue to Styling Corner Admin")}
                        </p>
                      </div>
                      <div style={{ marginLeft: "15%", marginRight: "15%" }}>
                        {!email && (
                          <Form
                            onSubmit={(e) => {
                              e.preventDefault();
                              validation.handleSubmit();
                              return false;
                            }}
                            action="#"
                          >
                            <div className="mb-3">
                              <Label htmlFor="email" className="form-label">
                                {props.t("username_email")}
                              </Label>
                              <Input
                                name="email"
                                className="form-control"
                                placeholder={props.t("enter_username_email")}
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email &&
                                  validation.errors.email
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.email &&
                              validation.errors.email ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.email}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div
                              className="mb-3"
                              onMouseLeave={handleFocusChange}
                            >
                              <div className="float-end">
                                <Link
                                  to="/forgot-password"
                                  className="text-muted"
                                >
                                  {props.t("Forgot password")}?
                                </Link>
                              </div>
                              <Label
                                className="form-label"
                                htmlFor="password-input"
                              >
                                {props.t("Password")}
                              </Label>
                              <div className="position-relative auth-pass-inputgroup mb-3">
                                <Input
                                  innerRef={inputRef}
                                  name="password"
                                  value={validation.values.password || ""}
                                  type={isViewed ? "text" : "password"}
                                  className="form-control pe-5"
                                  placeholder={props.t("enter_password")}
                                  onChange={validation.handleChange}
                                  invalid={
                                    validation.touched.password &&
                                    validation.errors.password
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.password &&
                                validation.errors.password ? (
                                  <FormFeedback type="invalid">
                                    {props.t(validation.errors.password)}
                                  </FormFeedback>
                                ) : null}
                                <Button
                                  className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted shadow-none"
                                  type="button"
                                  color="transparent"
                                  onClick={toggleView}
                                >
                                  <i
                                    className={
                                      isViewed
                                        ? "ri-eye-off-fill align-middle"
                                        : "ri-eye-fill align-middle"
                                    }
                                  ></i>
                                </Button>
                              </div>
                            </div>
                            <div className="form-check">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="auth-remember-check"
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="auth-remember-check"
                              >
                                {props.t("Remember me")}
                              </Label>
                            </div>
                            <div className="mt-4">
                              <Button
                                disabled={isLogingUser || isFetchingUser}
                                color="success"
                                className="btn btn-dark w-100"
                                type="submit"
                              >
                                {isLogingUser || isFetchingUser ? (
                                  <Spinner />
                                ) : (
                                  props.t("Sign In")
                                )}
                              </Button>
                            </div>
                            {loginErrors && (
                              <Alert
                                color="danger"
                                style={{ marginTop: "13px" }}
                              >
                                {props.t(loginErrors?.response?.data?.message)}
                              </Alert>
                            )}
                          </Form>
                        )}
                        {email && (
                          <Form
                            onSubmit={(e) => {
                              e.preventDefault();
                              verifyValidation.handleSubmit();
                              return false;
                            }}
                            action="#"
                          >
                            <div className="mb-3">
                              <Label htmlFor="email" className="form-label">
                                {props.t("Check your email for an OTP code")}
                              </Label>
                              <Input
                                name="otp"
                                className="form-control"
                                placeholder={props.t("OTP code")}
                                type="text"
                                onChange={verifyValidation.handleChange}
                                onBlur={verifyValidation.handleBlur}
                                value={verifyValidation.values.otp || ""}
                                invalid={
                                  verifyValidation.touched.otp &&
                                  verifyValidation.errors.otp
                                    ? true
                                    : false
                                }
                              />
                              {verifyValidation.touched.otp &&
                              verifyValidation.errors.otp ? (
                                <FormFeedback type="invalid">
                                  {props.t(verifyValidation.errors.otp)}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mt-4">
                              <Button
                                disabled={isLoging}
                                color="success"
                                className="btn btn-dark w-100"
                                type="submit"
                              >
                                {isLoging ? <Spinner /> : props.t("Sign In")}
                              </Button>
                            </div>
                            {failedLoging && (
                              <Alert style={{ marginTop: "8px" }}>
                                {props.t(failedLoging.message)}
                              </Alert>
                            )}
                          </Form>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col md={12} lg={6} xl={6} className="g-0">
              <Slide />
            </Col>
          </Row>
          <PoweredByIcon
            onClick={() =>
              window.open("https://resolut-technologies.ch/", "_blank")
            }
            style={{
              position: "fixed",
              cursor: "pointer",
              left: "30px",
              bottom: "20px",
            }}
          />
        </Container>
      </div>
    </div>
  );
};

export default withRouter(withTranslation()(Login));
