import React, { useContext, useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import "react-toastify/dist/ReactToastify.css";
//i18n
import { withTranslation } from "react-i18next";
import {
  useFindCompany,
  useUpdateCompany,
  useAddCompany,
} from "../../hooks/useCompany";
import {
  useDeleteSchedule,
  useUpdateSchedule,
} from "../../hooks/useWorkingTIme";
import { AuthContext } from "../../store/AuthContext";
import { useGetSchedule, useCreateDay } from "../../hooks/useWorkingTIme";
import Navigation from "../../Components/Settings/Company/Navigation/Navigation";
import CompanyDetailsForm from "../../Components/Settings/Company/CompanyDetailsForm";
import CompanyIframForm from "../../Components/Settings/Company/CompanyIframForm";
import CompanySocialMediaForm from "../../Components/Settings/Company/CompanySocialMediaForm";
import WorkingScedule from "../../Components/Settings/Company/WorkingScedule";
import { useFormik } from "formik";
import { isActiveOptions } from "../../data/selectOptions";

const NewCompany = (props) => {
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const {
    mutate: createCompany,
    isSuccess: isSuccessCreateCompany,
    isLoading: isCreatingCompany,
  } = useAddCompany();
  const {
    mutate: editCompany,
    isSuccess: isSuccessEditCompany,
    isLoading: isEditCompany,
    isError: isErrorEditingCompany,
    error,
    editingError,
  } = useUpdateCompany();
  const {
    mutate: editSchedule,
    isSuccess: isSuccessEditSchedule,
    isLoading: isEditSchedule,
    isError: isErrorEditingSchedule,
  } = useUpdateSchedule();
  const { mutate: deleteSchedule } = useDeleteSchedule();
  const { mutate: createDay } = useCreateDay();
  const { data: companyTime } = useGetSchedule(user?.companyId);
  const { data: company } = useFindCompany(user?.companyId);
  const [logo, setLogo] = useState([]);
  const [coverImages, setCoverImages] = useState([]);
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [allowEdit, setAllowEdit] = useState(false);
  const [createData, setCreateData] = useState([]);
  const toggleCustom = (tab) => {
    setcustomActiveTab(tab);
  };

  const handleUpdateCompany = (id, data) => {
    editCompany({ id, data });
  };

  const handleCreateCompany = (data) => {
    console.log(data);
    createCompany({ data: data });
  };

  const handleUpdateSchedule = (id, data) => {
    editSchedule({ id, data });
  };

  const handleAddDay = (data) => {
    createDay(data);
  };

  const handleDeleteDay = (id) => {
    deleteSchedule({ id });
  };

  useEffect(() => {
    if (isSuccessCreateCompany && !isCreatingCompany) {
      toast(props.t("Successfully аdded company!"), {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: false,
        className: "bg-success text-white",
      });
      history.push("/company");
    }
  }, [isSuccessCreateCompany, isCreatingCompany]);

  useEffect(() => {
    if (isSuccessEditCompany && !isEditCompany) {
      toast(props.t("Successfully edited company details!"), {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: false,
        className: "bg-success text-white",
      });
    }
  }, [isSuccessEditCompany, isEditCompany]);

  const [aboutError, setAboutError] = useState("");
  const [contentValue, setContentValue] = useState();
  const [agbValue, setAgbValue] = useState();

  useEffect(() => {
    if (isSuccessEditSchedule && !isEditSchedule) {
      toast(props.t("Successfully edited company working hours!"), {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: false,
        className: "bg-success text-white",
      });
    }
  }, [isSuccessEditSchedule, isEditSchedule]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      about_company: "",
      agb: "",
      allow_appointments: "0",
      is_super_company: "",
      isCompanyRegistered: "",
      uID: "",
      subCompaniesAllowed: 0,
      phone: "",
      fax: "",
      email: "",
      website: "",
      konkordac: "",
      gln_number: "",
      address: "",
      address_no: "",
      langutitude: "",
      longitude: "",
      facebook: "",
      instagram: "",
      linkedin: "",
      youtube: "",
      country: "ch",
      city: "",
      zip: "",
      company_type: ["pharmacy"],
      google_map: "",
      google_iframe: "",
    },
    validationSchema: Yup.object({
      company_name: Yup.string().required(props.t("Please Enter Company Name")),
      company_type: Yup.array().required(props.t("Please Enter Company Type")),
      is_super_company: Yup.string().required(
        props.t("Please Select Company Type")
      ),
      isCompanyRegistered: Yup.string().required(
        props.t("Select Company Registration Status")
      ),
      uID: Yup.string().test(
        "uID-validation",
        props.t("Please enter uID of company"),
        function (value) {
          if (this.parent.isCompanyRegistered !== "true") return true;
          if (!value) return false;
          const uidPattern = /^CHE-\d{3}\.\d{3}\.\d{3}$/;
          if (!uidPattern.test(value)) {
            return this.createError({
              path: "uID",
              message: props.t("uID must follow the pattern CHE-375.534.221"),
            });
          }
          return true;
        }
      ),
      subCompaniesAllowed: Yup.number()
        .transform((value, originalValue) => {
          return originalValue === "" ? undefined : value;
        })
        .test(
          "sub-companies-validation",
          props.t("Please enter number of sub companies"),
          function (value) {
            if (this.parent.is_super_company !== "true") return true;
            if (!value) return false;
            if (value <= 0) {
              return this.createError({
                path: "subCompaniesAllowed",
                message: props.t("Sub companies value must be greater than 0"),
              });
            }
            if (typeof value !== "number") {
              return this.createError({
                path: "subCompaniesAllowed",
                message: props.t("Sub companies value must be a number"),
              });
            }
            return true;
          }
        ),
      about_company: Yup.string().test(
        "about-company-validation",
        props.t("Please Enter About Company"),
        function (value) {
          if (!contentValue) return false;
          return true;
        }
      ),
      agb: Yup.string().test(
        "agb-validation",
        props.t("Please Enter Allgemeine Geschäftsbedingungen"),
        function (value) {
          if (!agbValue) return false;
          return true;
        }
      ),
      marketing: Yup.string().required(
        props.t("Please Enter Appointment Permission")
      ),
      google_map: Yup.string().required(
        props.t("Please Enter Location of Google Maps")
      ),
      google_iframe: Yup.string().required(
        props.t("Please Enter iFrame of Google Maps")
      ),
      is_active: Yup.string().oneOf(
        isActiveOptions.map((item) => item.value),
        props.t("You must select at least one of the options") +
          isActiveOptions.map((item) => item.label)
      ),
      phone: Yup.string().required(props.t("Please Enter Phone")),
      email: Yup.string().email().required(props.t("Please Enter Email")),
      address: Yup.string().required(props.t("Please Enter Address")),
      zip: Yup.string().required(props.t("Please Enter Zip")),
      city: Yup.string().required(props.t("Please Enter City")),
    }),
    onSubmit: (values, { resetForm }) => {
      let myFormData = new FormData();
      Object.keys(values).forEach((val) => {
        if (val === "company_type") {
          values[val].forEach((el, i) =>
            myFormData.append(val + "[]", values[val][i])
          );
        } else {
          myFormData.append(val, values[val]);
        }
      });
      if (logo.length > 0) {
        myFormData.append("logo", logo[0], logo[0].name);
      }
      if (coverImages.length > 0) {
        for (let i = 0; i < coverImages.length; i++) {
          myFormData.append("slider", coverImages[i]);
        }
      }
      myFormData.set("about_company", contentValue);
      myFormData.set("agb", agbValue);
      handleCreateCompany(myFormData);
      resetForm();
    },
  });

  useEffect(() => {
    if (contentValue) return setAboutError("");
  }, [contentValue]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <MetaTags>
            <title>
              {props.t("New Company")} | {props.t("MyHealth Brend")}
            </title>
          </MetaTags>
          <BreadCrumb
            title={props.t("New Company")}
            pageTitle={props.t("Company")}
          />

          <Row>
            <Col lg={11}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">{props.t("Company")}</h4>
                </CardHeader>

                <CardBody>
                  <Navigation
                    customActiveTab={customActiveTab}
                    toggleCustom={toggleCustom}
                    props={props.t}
                  />
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <TabContent activeTab={customActiveTab}>
                      <TabPane tabId="1" id="home1">
                        <CompanyDetailsForm
                          company={company}
                          validation={validation}
                          setAllowEdit={setAllowEdit}
                          setLogo={setLogo}
                          contentValue={contentValue}
                          setContentValue={setContentValue}
                          agbValue={agbValue}
                          setAgbValue={setAgbValue}
                          error={error}
                          toggleTab={toggleCustom}
                          setCoverImages={setCoverImages}
                          // handleCreateCompany={handleAddData}
                        />
                      </TabPane>

                      <TabPane tabId="3">
                        <CompanyIframForm
                          company={company}
                          validationIframe={validation}
                          toggleTab={toggleCustom}
                          // handleUpdateCompany={handleAddData}
                        />
                      </TabPane>

                      <TabPane tabId="4">
                        <CompanySocialMediaForm
                          company={company}
                          validationSocialMedia={validation}
                        />
                        <Button type="submit">Create</Button>
                      </TabPane>

                      <TabPane tabId="6">
                        <Row>
                          <Col log={12}>
                            <Alert color="info" className="shadow">
                              {props.t("Bank Account Info Alert")}
                            </Alert>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(NewCompany);
