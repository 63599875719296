import React, { useState } from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

const FormGroupText = ({
  idInput,
  label,
  isRequired,
  attributeName,
  onRegenerateClick,
  validation,
  type = "text",
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <FormGroup>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {label !== undefined && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Label htmlFor={idInput}>
              {label} {isRequired && <span className="text-danger">*</span>}
            </Label>
            {label === "UID Nr" && (
              <p style={{ marginTop: "0", marginBottom: "0.5rem" }}>
                Follow the pattern: CHE-123.456.789
              </p>
            )}
          </div>
        )}
        {onRegenerateClick && (
          <p
            onClick={onRegenerateClick}
            className="text-secondary"
            style={{
              cursor: "pointer",
              whiteSpace: "nowrap",
              marginBottom: "0.5rem",
              marginTop: 0,
            }}
          >
            Regenerate Password
          </p>
        )}
      </div>
      <div
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Input
          name={attributeName}
          className="form-control"
          id={idInput}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values[attributeName] || ""}
          invalid={
            validation.touched[attributeName] &&
            validation.errors[attributeName]
              ? true
              : false
          }
          type={showPassword ? "text" : type}
          {...props}
        />
        {type === "password" && (
          <div style={{ position: "absolute", right: 10 }}>
            {showPassword ? (
              <EyeIcon
                onClick={() => setShowPassword((prev) => !prev)}
                style={{ height: "24px", width: "24px", cursor: "pointer" }}
              />
            ) : (
              <EyeSlashIcon
                onClick={() => setShowPassword((prev) => !prev)}
                style={{ height: "24px", width: "24px", cursor: "pointer" }}
              />
            )}
          </div>
        )}
      </div>
      {validation.touched[attributeName] && validation.errors[attributeName] ? (
        <p
          style={{
            width: "100%",
            marginTop: "0.25rem",
            fontSize: "0.875em",
            color: "#f06548",
          }}
        >
          {validation.errors[attributeName]}
        </p>
      ) : null}
    </FormGroup>
  );
};

export default FormGroupText;
