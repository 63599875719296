import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  getAllWorkingTime,
  updateWorkingSchedule,
  createDay,
  deleteDay,
  getServiceWorkingTime,
} from "../services/workingTime";

export function useGetSchedule(id) {
  return useQuery(["schedule", id], () => getAllWorkingTime(id), {
    enabled: !!id,
  });
}

export function useGetServiceSchedule(id) {
  return useQuery(["schedule", id], () => getServiceWorkingTime(id), {
    enabled: !!id,
  });
}

export function useServiceScedule(id) {
  return useQuery(
    ["schedule", "service", id],
    () => getServiceWorkingTime(id),
    {
      enabled: !!id,
    }
  );
}

export function useCreateDay() {
  const queryClient = useQueryClient();
  return useMutation(createDay, {
    onSuccess: () => {
      queryClient.invalidateQueries(["schedule"]);
    },
  });
}

export function useUpdateSchedule() {
  const queryClient = useQueryClient();
  return useMutation(({ id, data }) => updateWorkingSchedule(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(["schedule"]);
    },
  });
}

export function useDeleteSchedule() {
  const queryClient = useQueryClient();
  return useMutation(({ id }) => deleteDay(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["schedule"]);
    },
  });
}
