import React, { createContext, useEffect } from "react";
import { useState } from "react";

import { verifiLogin } from "../services/authServices";

export const AuthContext = createContext({});

const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoging, setIsLoging] = useState(false);
  const [failedLoging, setFaildLoging] = useState(null);

  useEffect(() => {
    let client = localStorage.getItem("client");
    if (client) {
      setUser(JSON.parse(client));
    }
    if (!client) {
      setUser(null);
    }
  }, []);

  const login = (data) => {
    setIsLoging(true);
    setFaildLoging(null);
    verifiLogin(data)
      .then((res) => {
        setIsLoging(false);
        setFaildLoging(null);
        setUser(res);
        localStorage.setItem("clientAccessToken", res.accessToken);
        localStorage.setItem("client", JSON.stringify(res));
      })
      .catch((err) => {
        setIsLoging(false);
        setFaildLoging(err.response.data);
      });
  };

  const logout = () => {
    localStorage.removeItem("clientAccessToken");
    localStorage.removeItem("client");
    setUser(null);
  };
  return (
    <AuthContext.Provider
      value={{ user, login, isLoging, failedLoging, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
